import React, { useEffect, useState } from 'react'

import { images } from '../../assets/testPageImages'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AccessToken from '../../hooks/token/AccessToken'
import Loading from '../../components/common/Loading'

// utils
import { ShowErrorMsg } from '../../utils/ShowErrorMsg'

const LifeScoreMainPage = () => {

   const accessToken = AccessToken();
   const [eligibility, setEligibility] = useState(false);

   const checkEligibility = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/inspect/life/check`, {}, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         if (!response.status === 200) {
            alert('잘못된 접근입니다.')
            window.location.href = '/'
         }
         setEligibility(response.status === 200 ? true : false)
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
         window.location.href = '/'
      }
   }

   useEffect(() => {
      checkEligibility();
   }, [])

   if (!eligibility) return <Loading />

   return (
      <main>
         <div className="test-container">
            <div className="container row-group w860">

               <div className="intro-top">
                  <img src={images.life_score_intro} alt="" />
               </div>
               <div className="intro-content">
                  <div className="title-group row-group">
                     <p className="title green">
                        인생점수 검사 안내
                     </p>
                     <div className="txt-group row-group">
                        <div className="txt-item col-group">
                           <div className="item-default col-group">
                              <i className="icon"></i>
                              검사구성
                           </div>
                           <div className="item-user">
                              각 시기마다 제시되는 질문 문항으로 연령별 상담사들이
                           </div>
                        </div>
                        <div className="txt-item col-group">
                           <div className="item-default col-group">
                              <i className="icon"></i>
                              검사문항
                           </div>
                           <div className="item-user">
                              연령별 상이
                           </div>
                        </div>
                        <div className="txt-item col-group">
                           <div className="item-default col-group">
                              <i className="icon"></i>
                              소요시간
                           </div>
                           <div className="item-user">
                              연령별 상이
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="sub-txt-group row-group">
                     <p className="sub-txt">
                        인생점수 검사는 마인드 인사이트에서 무료로 진행하는 검사입니다.
                     </p>
                     <p className="sub-txt">
                        검사할 준비가 완료되었다면 아래 '검사시작' 버튼을 눌러 검사를 시작하세요.
                     </p>
                  </div>
                  <div className="form-footer">
                     <Link to='/life-score/info-input' className="form-footer-btn">검사시작</Link>
                  </div>
               </div>
            </div>
         </div>
      </main>
   )
}

export default LifeScoreMainPage
import axios from 'axios';
import React, { useState } from 'react'
import AccessToken from '../../../hooks/token/AccessToken';
import { ShowErrorMsg } from '../../../utils/ShowErrorMsg';

const RefundPopup = ({ refundPopup, setRefundPopup, fetchData }) => {

   // 환불 요청 팝업 닫기
   const closeRefundPopup = () => {
      setRefundPopup((prev) => ({
         ...prev,
         visible: false,
         data: null,
      }))
   }

   // 환불 요청에 필요한 데이터
   const refundInfo = refundPopup.data;

   // 환불 사유
   const [refundReasons, setRefundReasons] = useState('');
   const refundReasonChange = (e) => {
      const value = e.target.value;
      setRefundReasons(value);
   }

   // 환불 요청 보내기
   const accessToken = AccessToken();

   const submitRefund = async () => {
      try {
         await axios.post(`${process.env.REACT_APP_API_URL}/mypage/registration/refund`, {
            id: refundInfo.id,
            refund_content: refundReasons
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         alert('환불요청 되었습니다.')
         closeRefundPopup(); // 팝업 닫기
         fetchData(); // 리스트 새로고침
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
         window.location.href = '/'
      }
   }

   if (!refundPopup.visible || !refundPopup.data) return;

   return (
      <div className="modal-container">
         <div className="modal-wrap">
            <i onClick={closeRefundPopup} className="close-btn pc"></i>
            <i onClick={closeRefundPopup} className="close-btn mb"></i>
            <div className="modal-title-wrap center border">
               <p className="modal-title">
                  환불요청
               </p>
            </div>
            <div className="refund-modal-content">
               <div className="form-wrap">
                  <div className="form-list border">
                     <div className="prod-table-item col-group">
                        <div className="img-box">
                           <img src={refundInfo.info.img.file_addr} alt="" />
                        </div>
                        <div className="txt-wrap row-group">
                           <div className="title-group row-group">
                              <p className="prod-item-label">
                                 {refundInfo.type === 3 ? 'Mind Therapy' : 'Mind Insight'}
                              </p>
                              <p className="prod-item-title">
                                 {refundInfo.info.product_name}
                              </p>
                           </div>
                           <div className="title-group row-group">
                              <p className="prod-table-item-option">
                                 옵션 : {refundInfo.option_name}
                              </p>
                              <p className="prod-table-item-option">
                                 수량 : {refundInfo.real_buy_quantity}
                              </p>
                              <p className="prod-table-item-option">
                                 <strong className="red">환불가능수량 : {refundInfo.refund_quantity}</strong>
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="form-item row-group">
                     <div className="item-default">
                        어떤 문제가 있나요?
                     </div>
                     <div className="item-user">
                        <div className="form-textarea-wrap">
                           <textarea
                              onChange={refundReasonChange}
                              name=""
                              id=""
                              className="form-textarea h200"
                              placeholder="환불사유를 입력해 주세요"
                           />
                           {/* <div className="sticker">
                              <span>0</span>/200
                           </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="modal-footer">
               <button
                  onClick={submitRefund}
                  type='button'
                  className="modal-footer-btn wide green"
               >
                  환불요청
               </button>
            </div>
         </div>
      </div>
   )
}

export default RefundPopup
import React from 'react'
import { Link } from 'react-router-dom'

// Images, Videos
import { images } from '../assets/images'

const Footer = ({ hideRightContent }) => {
   return (
      <footer id='ft'>
         <div className="footer-wrap">
            <div className="container col-group">
               <div className="left row-group">
                  <Link to="/" className="footer-logo">
                     <img src={images.footer_logo} alt="" />
                  </Link>
                  <div className="footer-link-wrap col-group">
                     <Link to="/policy/privacy" className="footer-link">
                        개인정보처리방침
                     </Link>
                     <Link to="/policy/service" className="footer-link">
                        이용약관
                     </Link>
                  </div>
                  <div className="footer-txt-wrap row-group">
                     <div className="footer-txt-group col-group">
                        <p className="footer-txt">(주)마인드아이티</p>
                        <p className="footer-txt">대표이사 : 유영서</p>
                        <p className="footer-txt">주소 : 경기도 고양시 덕양구 삼송로 240, 힐스테이트삼송역스칸센 202동 248호</p>
                     </div>
                     <div className="footer-txt-group col-group">
                        <p className="footer-txt">사업자등록번호 : 117-86-02993</p>
                        <p className="footer-txt">대표번호 : 02-381-2024</p>
                        <p className="footer-txt">이메일 : main@kmindit.com</p>
                        <p className="footer-txt">통신판매업 신고번호 : 2024-고양덕양구-3022</p>
                     </div>
                  </div>
                  <p className="footer-copy-txt">
                     Copyright 2024 (주)마인드아이티 All rights reserved.
                  </p>
                  <div className="sns-btn-wrap col-group">
                     <a href="https://pf.kakao.com/_wZlxkG" className="sns-btn" target='_blank' rel='noreferrer noopener'>
                        <img src={images.sns_btn_kc} alt="" />
                     </a>
                     <a href="https://www.instagram.com/mindit_2024/?next=%2F" className="sns-btn" target='_blank' rel='noreferrer noopener'>
                        <img src={images.sns_btn_insta} alt="" />
                     </a>
                     <a href="https://blog.naver.com/mindit2024" className="sns-btn" target='_blank' rel='noreferrer noopener'>
                        <img src={images.sns_btn_blog} alt="" />
                     </a>
                     <a href="https://www.youtube.com/channel/UC5Dcc6AqUYXrmPjsxzWXvkA" className="sns-btn" target='_blank' rel='noreferrer noopener'>
                        <img src={images.sns_btn_ytb} alt="" />
                     </a>
                     <a href="https://story.kakao.com/mindit2024" className="sns-btn" target='_blank' rel='noreferrer noopener'>
                        <img src={images.sns_btn_ks} alt="" />
                     </a>
                     <a href="https://band.us/band/95234117" className="sns-btn" target='_blank' rel='noreferrer noopener'>
                        <img src={images.sns_btn_band} alt="" />
                     </a>
                     <a href="https://www.facebook.com/profile.php?id=61560913262056" className="sns-btn" target='_blank' rel='noreferrer noopener'>
                        <img src={images.sns_btn_fb} alt="" />
                     </a>
                  </div>
               </div>
               {!hideRightContent &&
                  <div className="right row-group">
                     <p className="footer-inquiry-title">고객센터</p>
                     <Link to="tel:02-381-2024" className="footer-inquiry-num">
                        02-381-2024
                     </Link>
                     <p className="footer-inquiry-txt">
                        평일 10:00 ~ 15:00 (점심시간 12:00 ~13:00) <br />
                        주말 및 공휴일은 휴무입니다.
                     </p>
                     <Link to="/my-page/inquiry" className="footer-inquiry-btn">1:1 문의</Link>
                  </div>
               }
            </div>
         </div>
      </footer>
   )
}

export default Footer
import React from 'react'

const Payment = () => {
   return (

      <div className="subpage policy">
         <h2 className="policy-title">
            전자금융거래 이용약관
         </h2>

         <div className="policy-page">

            <div className="fulltext-item">
               <h2>
                  [제1장 총칙]
               </h2>
               <p className="fulltext-title fulltext-title-bold">
                  제 1 조 (목적)
               </p>
               <p className="padding-bottom">
                  본 약관은 주식회사 마인드아이티(이하 "회사"라 합니다)가 제공하는 전자지급결제대행서비스의 관리서비스 등(이하 통칭하여 “전자금융거래서비스”)을 이용자가 이용함에 있어, "회사"와 이용자 간 권리, 의무 및 이용자의 전자금융거래서비스 이용절차 등에 관한 사항을 규정하는 것을 목적으로 합니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 2 조 (용어의 정의)
               </p>
               <p className="padding-bottom">
                  ① 본 약관에서 정한 용어의 정의는 아래와 같습니다.
               </p>
               <div className="padding-left">
                  <p className="padding-bottom">
                     1. "전자금융거래"라 함은 "회사"가 "전자적 장치"를 통하여 전자금융업무를 제공하고, 이용자가 "회사"의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     2. “전자지급수단”이라 함은 전자자금이체, 직불전자지급수단, 선불전자지급수단, 전자화폐, 신용카드, 전자채권 그 밖에 전자적 방법에 따른 지급 수단을 말합니다.
                  </p>
                  <p className="padding-bottom">
                     3. "전자지급거래"라 함은 자금을 주는 자가 "회사"로 하여금 전자지급수단을 이용하여 자금을 받는 자에게 자금을 이동하게 하는 "전자금융거래"를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     4. "전자적 장치"라 함은 "전자금융거래"정보를 전자적 방법으로 전송하거나 처리하는 데 이용되는 장치로서 현금자동지급기, 자동입출금기, 지급용단말기, 컴퓨터, 전화기 그 밖에 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     5. "접근매체"라 함은 "전자금융거래"에 있어서 "거래지시"를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다), 「전자서명법」상의 인증서, ‘회사’에 등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 「전자금융거래법」 제2조 제10호에서 정하고 있는 것을 말합니다.
                  </p>
                  <p className="padding-bottom">
                     6. “이용자”라 함은 본 약관에 동의하고 본 약관에 따라 "회사"가 제공하는 "전자금융거래서비스"를 이용하는 자를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     7. "거래지시"라 함은 이용자가 본 약관에 따라 "회사"에게 "전자금융거래"의 처리를 지시하는 것을 말합니다.
                  </p>
                  <p className="padding-bottom">
                     8. "오류"라 함은 이용자의 고의 또는 과실 없이 "전자금융거래"가 본 약관 또는 이용자의 "거래지시"에 따라 이행되지 아니한 경우를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     9. “정보통신망”이라 함은 전기통신설비를 이용하거나 전기통신설비와 컴퓨터 및 컴퓨터의 이용기술을 활용하여 정보를 수집∙가공∙검색∙송신 또는 수신하는 정보통신체제를 말합니다.
                  </p>
               </div>
               <p className="padding-bottom">
                  ② 본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 「전자금융거래법」 등 관련 법령에 정한 바에 따릅니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 3 조 (약관의 명시 및 효력변경)
               </p>
               <div className="padding-left">
                  <p className="padding-bottom">
                     ① "회사"는 이용자가 "전자금융거래"를 하기 전에 본 약관을 서비스 페이지에 게시하고 본 약관의 중요한 내용을 확인할 수 있도록 합니다.
                  </p>
                  <p className="padding-bottom">
                     ② "회사"는 이용자의 요청이 있는 경우 전자문서의 전송(전자우편을 이용한 전송을 포함합니다)의 방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.
                  </p>
                  <p className="padding-bottom">
                     ③ 회사"가 본 약관을 변경하는 때에는 그 시행일 1개월 전에 변경되는 약관을 전자금융거래서비스 이용 초기화면 또는 “회사” 홈페이지에 게시하고, 이용자에게 통지합니다. 다만, 법령의 개정으로 인하여 긴급하게 약관을 변경한 때에는 변경된 약관을 전자금융거래서비스 이용 초기화면 또는 “회사” 홈페이지에 즉시 게시하고 이용자에게 통지하며, 변경되는 약관에 따른 이용자의 이의제기시 “회사”는 통지사실을 확인해 줄 의무가 있습니다.
                  </p>
                  <p className="padding-bottom">
                     ④ 이용자는 변경에 동의하지 아니한 경우 제 3 항에 따라 변경내용이 게시되거나 통지된 날로부터 변경되는 약관의 시행일 전의 영업일까지 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 봅니다.
                  </p>
               </div>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 4 조 (이용시간)
               </p>
               <p className="padding-bottom">
                  ① “회사”는 이용자에게 연중무휴, 1일 24시간 전자금융거래서비스를 제공함을 원칙으로 합니다. 단, 금융기관 기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다.
               </p>
               <p className="padding-bottom">
                  ② “회사”는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융기관 기타 결제수단 발행업자의 사정에 의하여 전자금융거래서비스 중단이 불가피한 경우, 중단 3일 전까지 게시가능한 전자적 수단을 통하여 전자금융거래서비스 중단 사실을 게시한 후 서비스를 일시 중단할 수 있습니다. 다만, 시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시없이 전자금융거래서비스를 중단할 수 있으나, 가능한 즉시 사후 공지합니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 5조 (거래내용의 확인)
               </p>
               <p className="padding-bottom">
                  ① "회사"는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 "오류정정요구사실 및 처리결과에 관한 사항"을 포함합니다)을 확인할 수 있도록 하며, 이용자가 거래내용에 대해 서면교부를 요청하는 경우에는 요청을 받은 날로부터 2주 이내에 모사전송, 우편 또는 직접교부의 방법으로 거래내용에 관한 서면을 교부합니다.
               </p>
               <p className="padding-bottom">
                  ② "회사"는 제1항에 따른 이용자의 거래내용 서면교부 요청을 받은 경우 전자적 장치의 운영장애, 그 밖의 이유로 거래내용을 제공할 수 없는 때에는 즉시 이용자에게 전자문서 전송(전자우편을 이용한 전송을 포함합니다)의 방법으로 그러한 사유를 알려야 하며, 전자적 장치의 운영장애 등의 사유로 거래내용을 제공할 수 없는 기간은 제1항의 거래내용에 관한 서면의 교부기간에 산입하지 아니합니다.
               </p>
               <p className="padding-bottom">
                  ③ 제1항의 대상이 되는 거래내용 중 대상기간이 5년인 것은 다음 각 호와 같습니다.
               </p>
               <div className="padding-left">
                  <p className="padding-bottom">
                     1. 거래계좌의 명칭 또는 번호
                  </p>
                  <p className="padding-bottom">
                     2. 전자금융거래의 종류 및 금액
                  </p>
                  <p className="padding-bottom">
                     3. 전자금융거래상대방을 나타내는 정보
                  </p>
                  <p className="padding-bottom">
                     4. 전자금융거래일시
                  </p>
                  <p className="padding-bottom">
                     5. "전자적 장치"의 종류 및 "전자적 장치"를 식별할 수 있는 정보
                  </p>
                  <p className="padding-bottom">
                     6. "회사"가 "전자금융거래"의 대가로 받은 수수료
                  </p>
                  <p className="padding-bottom">
                     7. 이용자의 출금 동의에 관한 사항
                  </p>
                  <p className="padding-bottom">
                     8. 해당 "전자금융거래"와 관련한 "전자적 장치"의 접속기록
                  </p>
                  <p className="padding-bottom">
                     9. "전자금융거래"의 신청 및 조건의 변경에 관한 사항
                  </p>
                  <p className="padding-bottom">
                     10. 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록
                  </p>
               </div>
               <p className="padding-bottom">
                  ④ 제1항의 대상이 되는 거래내용 중 대상기간이 1년인 것은 다음 각호와 같습니다.
               </p>
               <div className="padding-left">
                  <p className="padding-bottom">
                     1. 전자지급수단 이용과 관련된 거래승인에 관한 기록
                  </p>
                  <p className="padding-bottom">
                     2. 이용자의 오류정정 요구사실 및 처리결과에 관한 사항
                  </p>
                  <p className="padding-bottom">
                     3. 기타 금융위원회가 고시로 정한 사항
                  </p>
                  <p className="padding-bottom">
                     4. 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록
                  </p>
               </div>
               <p className="padding-bottom">
                  ⑤ 이용자가 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.
               </p>
               <div className="padding-left">
                  <p className="padding-bottom">
                     - 주소: 경기도 고양시 덕양구 삼송로240, 248호
                  </p>
                  <p className="padding-bottom">
                     - E-mail: main@kmindit.com
                  </p>
                  <p className="padding-bottom">
                     - 전화번호: 02.381.2024
                  </p>
               </div>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 6 조 (“거래지시”의 철회)
               </p>
               <p className="padding-bottom">
                  ① '회사'는 이용자의 거래지시가 전자지급거래에 관한 경우 그 지급절차를 대행하며, 전자지급거래에 관한 거래지시의 내용을 전송하여 지급이 이루어지도록 합니다.
               </p>
               <p className="padding-bottom">
                  ② 이용자가 "회사"의 "전자금융거래서비스"를 이용하여 전자지급거래를 한 경우, 이용자는 지급의 효력이 발생하기 전까지 본 약관에서 정한 바에 따라 제5조 제5항에 기재된 연락처로 전자문서의 전송(전자우편을 이용한 전송을 포함합니다) 또는 서비스 페이지 내 철회에 의한 방법으로 "거래지시"를 철회할 수 있습니다. 단, 각 서비스 별 "거래지시" 철회의 효력 발생시기는 본 약관 제18조에서 정한 바에 따릅니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 7 조 (추심이체 출금동의)
               </p>
               <p className="padding-bottom">
                  ① "회사"는 이용자의 요청이 있는 경우 이용자의 계좌가 개설되어 있는 금융회사 등이 추심이체를 실행할 수 있도록 금융회사 등을 대신하여 전자금융거래법령 등 관련 법령에 따른 방법으로 출금에 대한 동의를 진행합니다.
               </p>
               <p className="padding-bottom">
                  ② "회사"는 전 항에 따른 이용자의 동의 사항을 추심 이체를 실행하는 해당 금융회사 등에 제출합니다.
               </p>
               <p className="padding-bottom">
                  ③ 이용자는 "회원"의 계좌의 원장에 출금기록이 끝나기 전까지 "회사" 또는 해당 금융회사 등에 제1항의 규정에 따른 동의의 철회를 요청할 수 있습니다.
               </p>
               <p className="padding-bottom">
                  ④ 전 항에도 불구하고 "회사" 또는 금융회사 등은 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경우에는 미리 이용자와 정한 약정에 따라 동의의 철회시기를 달리 정할 수 있습니다.
               </p>
               <p className="padding-bottom">
                  ⑤ 이용자가 본 조 제3항에 따라 출금 동의 철회를 요청한 경우에도 이용자는 동의 철회에 대한 의사표시 이전에 발생한 출금에 대해서는 이의를 제기할 수 없습니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 8 조 (“오류”의 정정 등)
               </p>
               <p className="padding-bottom">
                  ① 이용자는 "전자금융거래서비스"를 이용함에 있어 "오류"가 있음을 안 때에는 "회사"에 대해 그 정정을 요구할 수 있으며, "회사"는 이용자의 정정 신청이 있는 경우 이를 조사하여 "오류"가 있는 경우 정정 절차를 진행하고 정정요구를 받은 날로부터 2주 이내에 그 결과를 이용자에게 알려드립니다.
               </p>
               <p className="padding-bottom">
                  ② "회사"는 스스로 "전자금융거래"에 "오류"가 있음을 안 때에도 이를 즉시 조사하여 처리한 후 그 결과를 2주 이내에 문서로 이용자에게 알려 드립니다. 다만, 이용자의 주소가 분명하지 아니하거나 이용자가 요청한 경우에는 전화 또는 전자우편 등의 방법으로 알릴 수 있습니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 9 조 (“전자금융거래” 기록의 생성 및 보존)
               </p>
               <p className="padding-bottom">
                  ① "회사"는 이용자가 이용한 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.
               </p>
               <p className="padding-bottom">
                  ② 제1항의 규정에 따라 "회사"가 보존하여야 하는 기록의 종류 및 보존방법은 제5조에 따릅니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 10 조 ("전자금융거래"정보의 제공금지)
               </p>
               <p className="padding-bottom">
                  ① "회사"는 "전자금융거래서비스"를 제공함에 있어서 취득한 이용자의 인적 사항, 이용자의 계좌, "접근매체" 및 "전자금융거래"의 내용과 실적에 관한 정보 또는 자료를 법령에 의하거나 이용자의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무 상 목적 외에 사용하지 아니합니다. 다만, 「금융실명거래 및 비밀보장에 관한 법률」 제4조 제1항 단서의 규정에 따른 경우 및 그 밖에 다른 법률에서 정하는 바에 따른 경우에는 그러하지 아니합니다.
               </p>
               <p className="padding-bottom">
                  ② "회사"는 이용자가 안전하게 "전자금융거래서비스"를 이용할 수 있도록 이용자의 개인정보보호를 위하여 개인정보처리방침을 운용합니다. "회사"의 개인정보처리방침은 "회사"의 홈페이지에서 확인할 수 있습니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 11 조 ("회사”의 책임)
               </p>
               <p className="padding-bottom">
                  ① “회사”는 다음 각 호의 사유 발생으로 인하여 이용자에게 손해가 발생하였을 경우 이에 대한 배상책임을 부담합니다.
               </p>
               <div className="padding-left padding-bottom">
                  <p className="padding-bottom">
                     1. 접근매체의 위조나 변조로 발생한 사고(단, “회사”가 “접근매체”의 발급 주체이거나 사용, 관리 주체인 경우)
                  </p>
                  <p className="padding-bottom">
                     2. 계약체결 또는 거래지시의 전자적 전송이나 처리 과정에서 발생한 사고
                  </p>
                  <p className="padding-bottom">
                     3. 전자금융거래를 위한 전자적 장치 또는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제2조제1항제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고
                  </p>
               </div>
               <p className="padding-bottom">
                  ② 제1항에도 불구하고 “회사”는 이용자의 고의 또는 중과실로 다음 각 호의 행위를 하였음을 증명하는 경우에는 이용자에게 손해가 생기더라도 책임의 전부 또는 일부를 지지 아니합니다.
               </p>
               <div className="padding-left padding-bottom">
                  <p className="padding-bottom">
                     1. 이용자가 "접근매체"를 제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나 담보의 목적으로 제공한 경우(「전자금융거래법」 제18조에 따라 선불전자지급수단이나 전자화폐를 양도하거나 담보로 제공한 경우는 제외합니다.)
                  </p>
                  <p className="padding-bottom">
                     2. 제3자가 권한 없이 이용자의 "접근매체"를 이용하여 "전자금융거래"를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 "접근매체"를 누설하거나 노출 또는 방치한 경우
                  </p>
                  <p className="padding-bottom">
                     3. 법인(「중소기업기본법」 제2조 제2항에 의한 소기업을 제외합니다)인 이용자에게 손해가 발생한 경우로서 "회사"가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우
                  </p>
                  <p className="padding-bottom">
                     4. 전자금융거래시 보안강화를 위하여 “회사”가 제19조 제1항의 따른 확인 외에 추가적인 보안조치를 요구하였음에도 불구하고 이용자가 정당한 사유 없이 이를 거부하여 “회사”의 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 사고가 발생한 경우
                  </p>
               </div>
               <p className="padding-bottom">
                  ③ "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체의 사유가 발생하여 전자금융서비스의 제공을 일시적으로 중단할 경우에는 제4조 제2항에 따라 "회사"의 홈페이지 또는 서비스 페이지를 통하여 이용자에게 중단 일정 및 중단 사유를 사전에 공지합니다. 단, 시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시없이 전자금융거래서비스를 중단할 수 있으나, 가능한 즉시 사후 공지합니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 12 조 (분쟁처리 및 분쟁조정)
               </p>
               <p className="padding-bottom">
                  ① 이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 서비스 이용과 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.
               </p>
               <div className="padding-left padding-bottom">
                  <p className="padding-bottom">
                     - E-mail: main@kmindit.com
                  </p>
                  <p className="padding-bottom">
                     - 연락처: 02.381.2024
                  </p>
               </div>
               <p className="padding-bottom">
                  ② 이용자가 "회사"에 대하여 분쟁처리를 신청한 경우에는 "회사"는 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게 안내 합니다.
               </p>
               <p className="padding-bottom">
                  ③ 이용자는 "회사"의 분쟁처리결과에 대하여 이의가 있을 경우 「금융위원회의 설치 등에 관한 법률」 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 「소비자기본법」 제60조 제1항의 규정에 따른 한국소비자원의 소비자분쟁조정위원회에 "회사"의 "전자금융거래서비스"의 이용과 관련한분쟁조정을 신청할 수 있습니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 13 조 (“회사”의 안정성 확보 의무)
               </p>
               <p className="padding-bottom">
                  "회사"는 전자금융거래가 안전하게 처리될 수 있도록 선량한 관리자로서의 주의를 다하며, "전자금융거래"의 안정성과 신뢰성을 확보할 수 있도록 "전자금융거래"의 종류 별로 전자적 전송이나 처리를 위한 인력, 시설, "전자적 장치" 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가 정하는 기준을 준수합니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 14 조 (약관 외 준칙)
               </p>
               <p className="padding-bottom">
                  ① '회사'와 이용자 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는 그 합의사항을 이 약관에 우선하여 적용합니다.
               </p>
               <p className="padding-bottom">
                  ② 이 약관에서 정하지 아니한 사항에 대하여는 「전자금융거래법」, 전자상거래 등에서의 소비자 보호에 관한 법률」, 「여신전문금융업법」 등 소비자보호 관련 법령에서 정한 바에 따릅니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 15 조 (관할)
               </p>
               <p className="padding-bottom">
                  "회사"와 이용자 간에 발생한 분쟁에 관한 관할은 「민사소송법」에서 정한 바에 따릅니다.
               </p>
            </div>

            <div className="fulltext-item">
               <h2>
                  제 2 장 전자지급결제대행 서비스
               </h2>
               <p className="fulltext-title fulltext-title-bold">
                  제 16 조 (정의)
               </p>
               <p className="padding-bottom">
                  본 장에서 정하는 용어의 정의는 아래와 같습니다.
               </p>
               <div className="padding-left">
                  <p className="padding-bottom">
                     1. "전자지급결제대행 서비스"라 함은 전자적 방법으로 재화 또는 용역(이하 이 약관에서 "재화 등"이라고 합니다)의 구매에 있어서 지급결제 정보를 송신하거나 수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     2. “이용자”라 함은 본 약관에 동의하고 "회사"가 제공하는 "전자지급결제대행 서비스"를 이용하는 자를 말합니다.
                  </p>
               </div>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 17 조(전자지급결제대행 서비스의 종류)
               </p>
               <p className="padding-bottom">
                  “회사”가 제공하는 “전자지급결제대행 서비스”는 지급결제수단에 따라 다음과 같이 구별됩니다.
               </p>
               <div className="padding-left">
                  <p className="padding-bottom">
                     1. 신용카드결제대행서비스: 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우로서, ‘회사’가 전자결제시스템을 통하여 신용카드 지불정보를 송∙수신하고 결제대금의 정산을 대행하거나 매개하는 “전자지급결제대행서비스”를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     2. 계좌이체결제대행서비스: 이용자가 결제대금을 “회사”의 전자결제시스템을 통하여 금융기관에 등록한 자신의 계좌에서 출금하여 원하는 계좌로 이체할 수 있는 실시간 송금 “전자지급결제대행 서비스”를 말합니다.
                  </p>
                  <p className="padding-bottom">
                     3. 가상계좌결제대행서비스: 이용자가 결제대금을 현금으로 결제하고자 하는 경우 ‘회사’의 전자결제시스템을 통하여 자동으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는 “전자지급결제대행 서비스”를 말합니다.
                  </p>
               </div>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 18 조 (“거래지시”의 철회)
               </p>
               <p className="padding-bottom">
                  ① 이용자가 "전자지급결제대행서비스"를 이용한 경우, 이용자는 "거래지시"된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관 또는 "회사"의 계좌의 원장에 입금기록 또는 전자적 장치에의 입력이 끝나기 전까지 "거래지시"를 철회할 수 있습니다.
               </p>
               <p className="padding-bottom">
                  ② "회사"는 이용자의 "거래지시"의 철회에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 이용자에게 반환하여야 합니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  제 19 조 (“접근매체”의 관리)
               </p>
               <p className="padding-bottom">
                  ① "회사"는 "전자지급결제대행 서비스" 제공 시 "접근매체"를 선정하여 이용자의 신원, 권한 및 "거래지시"의 내용 등을 확인합니다.
               </p>
               <p className="padding-bottom">
                  ② 이용자는 "접근매체"를 사용함에 있어서 다른 법률에 특별한 규정이 없는 한 다음 각 호의 행위를 하여서는 아니 됩니다.
               </p>
               <div className="padding-bottom padding-left">
                  <p className="padding-bottom">
                     1. "접근매체"를 양도하거나 양수하는 행위
                  </p>
                  <p className="padding-bottom">
                     2. "접근매체"를 대여하거나 사용을 위임하는 행위
                  </p>
                  <p className="padding-bottom">
                     3. "접근매체"를 질권 기타 담보 목적으로 하는 행위
                  </p>
                  <p className="padding-bottom">
                     4. 1호부터 3호까지의 행위를 알선하는 행위
                  </p>
               </div>
               <p className="padding-bottom">
                  ③ 이용자는 자신의 "접근매체"를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, "접근매체"의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
               </p>
               <p className="padding-bottom">
                  ④ "회사"는 이용자로부터 "접근매체"의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 "접근매체"를 사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.
               </p>
            </div>
            <div className="fulltext-item">
               <p className="fulltext-title fulltext-title-bold">
                  부 칙
               </p>
               <p className="padding-bottom">
                  본 약관은 2024년 12월 20일부터 적용됩니다.
               </p>
            </div>
         </div>

         <div id="footer"></div>
      </div>
   )
}

export default Payment
import { atom } from "recoil";

export const PdfProgressAtom = atom({
   key: 'pdfProgressAtom',
   default: 0
})

export const PdfDownloadStartAtom = atom({
   key: 'PdfDownloadStartAtom',
   default: {
      start: false,
      inspectId: '',
      name: '',
      phone: '',
      resultPageData: {},
   },
})

export const LifeScorePdfDownloadStartAtom = atom({
   key: 'LifeScorePdfDownloadStartAtom',
   default: {
      start: false,
      birth: '',
      resultPageData: {},
   },
})
import React, { useState, useEffect } from 'react';
import CommonUi from '../../../components/insight-test/CommonUi';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

// Components
import Loading from '../../../components/common/Loading';
import axios from 'axios';
import LongTimeLoading from '../../../components/common/LongTimeLoading';
import { useSetRecoilState } from 'recoil';
import { LifeScorePdfDownloadStartAtom, PdfDownloadStartAtom } from '../../../recoil/PdfDownloadAtom';
import AccessToken from '../../../hooks/token/AccessToken';
import { ShowErrorMsg } from '../../../utils/ShowErrorMsg';

const TestList = ({ currentPage, finalQuestions, setFinalQuestions }) => {

   // page가 바뀌면 맨위로 자동 스크롤
   useEffect(() => {
      const container = document.querySelector('.test-content.insight-test');
      container.scrollTop = 0;
      window.scrollTo(0, 0);
   }, [])

   // 체크했을 때 자동 스크롤
   useEffect(() => {
      const container = document.querySelector('.test-content.insight-test');
      const questions = document.querySelectorAll('.question');

      if (!container) return;

      function scrollToElement(element, offset = 0) {
         if (window.innerWidth <= 1024) {
            // 윈도우 사이즈가 1024 이하일 때 윈도우 스크롤
            window.scrollTo({
               top: element.getBoundingClientRect().top + window.scrollY - offset,
               behavior: 'smooth'
            });
         } else {
            // 컨테이너 스크롤
            container.scrollTo({
               top: element.offsetTop - container.offsetTop - offset,
               behavior: 'smooth'
            });
         }
      }

      const cleanUpFns = [];
      questions.forEach((question, index) => {
         const radios = question.querySelectorAll('input[type="radio"]');

         radios.forEach(radio => {
            const handleChange = () => {
               question.classList.add('prev');
               question.classList.remove('active');

               if (index < questions.length - 1) {
                  const nextQuestion = questions[index + 1];
                  nextQuestion.classList.add('active');

                  scrollToElement(nextQuestion, 112);
               }
            }
            radio.addEventListener('change', handleChange);

            // Clean-up 함수에 추가
            cleanUpFns.push(() => {
               radio.removeEventListener('change', handleChange);
            });
         });
      });

      // Clean-up 함수
      return () => {
         cleanUpFns.forEach(fn => fn());
      };
   }, [currentPage])

   // 값 선택
   const radioChange = (questionIndexParms, scoreIndexParms) => {

      setFinalQuestions((prev) => {
         // 이전 데이터를 깊은 복사
         const updateData = [...prev];

         // 현재 페이지의 items를 선택 후 반복문
         const currentPageItems = updateData[currentPage - 1].items.map((item) => {
            // 바꿔야 할 item 데이터를 questionIndex값과 questionIndexParms를 비교해서 찾음
            if (item.questionIndex === questionIndexParms) {
               // 바꿔야할 데이터를 찾았으면 score를 반복문
               const updatedScores = item.score.map((score, index) => {
                  // 바꿔야할 score 데이터를 index값과 scoreIndexParms를 비교해서 찾음
                  if (index === scoreIndexParms) {
                     // 바꿔야할 데이터를 찾았으면 checked를 true로 변경
                     return { ...score, checked: true };
                  }
                  return { ...score, checked: false }; // 다른 score의 checked는 false로 변경
               });

               // 업데이트 된 score 데이터와 함께 반환
               return { ...item, score: updatedScores };
            }
            return item; // 업데이트 되지 않은 다른 item은 그대로 유지
         });

         // 업데이트된 데이터를 현재 페이지에 반영
         updateData[currentPage - 1] = {
            ...updateData[currentPage - 1],
            items: currentPageItems,
         };

         return updateData;
      });
   };

   if (!finalQuestions) return;

   return (
      <div className="test-list">
         {currentPage <= finalQuestions.length &&
            finalQuestions[currentPage - 1].items.map((question, index) => (
               <div className={`${index === 0 ? 'active' : ''} question`} key={index}>
                  <div className="q_title">
                     <div className="num">{question.questionIndex}.</div>
                     <div className="title">{question.title}</div>
                  </div>
                  <div className="q_item life">
                     {question.score.map((score, scoreIndex) => {
                        if (!score.label) {
                           return (
                              <label htmlFor={`q_${question.questionIndex}_${scoreIndex + 1}`} className="score" key={scoreIndex}>
                                 <input
                                    onChange={() => radioChange(question.questionIndex, scoreIndex)}
                                    checked={score.checked}
                                    type="radio"
                                    id={`q_${question.questionIndex}_${scoreIndex + 1}`}
                                    name={`q_${question.questionIndex}`}
                                 // className={`q_${question.questionIndex}`}
                                 // value={score.number}
                                 />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">{score.number}</div>
                                 </div>
                              </label>
                           )
                        } else return '';
                     })}
                  </div>
               </div>
            ))}
      </div>
   )
}

const Content = () => {
   const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // 쿠키 데이터 복호화에 쓸 키

   const accessToken = AccessToken();

   const [currentPage, setCurrentPage] = useState(1);
   const [userData, setUserData] = useState({ age: null });

   const [resultPageData, setResultPageData] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [isLoadingVisible, setIsLoadingVisible] = useState(false);

   // 복호화 함수
   const decrypt = (cipherText) => {
      const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
      return bytes.toString(CryptoJS.enc.Utf8);
   };

   // 쿠키에서 JSON 데이터를 읽어와 복호화
   useEffect(() => {
      const encryptedData = Cookies.get('userData');
      if (encryptedData) {
         const decryptedData = decrypt(encryptedData); // 복호화
         setUserData(JSON.parse(decryptedData)); // 문자열을 JSON 객체로 변환
      } else {
         alert("잘못된 접근입니다.");
         window.location.href = '/';

         return;
      }
   }, []);

   // 검사 답변 내용
   const scoreType3 = [
      { checked: false, number: 10 },
      { checked: false, number: 9 },
      { checked: false, number: 8 },
      { checked: false, number: 7 },
      { checked: false, number: 6 },
      { checked: false, number: 5 },
      { checked: false, number: 4 },
      { checked: false, number: 3 },
      { checked: false, number: 2 },
      { checked: false, number: 1 },
   ];

   const [finalQuestions, setFinalQuestions] = useState([
      {
         name: '인생점수(영유아기·아동기) ',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: "01",
               title: "당신의 어머니는 친절한 분이었나요? (친절할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "02",
               title: "당신의 아버지는 친절한 분이었나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "03",
               title: "형제, 자매들과의 기억은 긍정적인가요? (긍정적일수록 높은 점수) (외동일 경우, 형제자매 없이 외동으로 혼자 자라는 것에 만족했나요?)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: "04",
               title: "당신을 괴롭혔던 친구들에 대한 기억이 있나요? (기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "05",
               title: "어린시절, 떠올리면 기분 좋은, 친구들에 대한 기억이 있나요? (기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "06",
               title: "10세 이전에 만났던 선생님들에 대한 기억은 긍정적인가요? (긍정적일수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: "07",
               title: "또래 친구들과 어울리면서 왕따를 경험한 기억이 있나요? (기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: "08",
               title: "성장하면서 몸이 아프거나 크게 다친 경험이 있나요? (기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "09",
               title: "많은 사람들 앞에서 말하거나 노래하면서 기분좋았던 기억이 있나요? (기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "10",
               title: "나의 최초의 기억(가장 오래된 기억)은 긍정적인 장면인가요? (긍정적일수록 높은 점수)",
               score: scoreType3
            }
         ]
      },
      {
         name: '인생점수(10대)',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: "11",
               title: "학교에서 성적은 만족할만한 수준이었나요? (학교에 다니지 않았다면, 이 시절 학업에 대한 기억은 긍정적인가요?)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "12",
               title: "이 시절 나의 외모에 만족했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "13",
               title: "청소년기 연애경험은 건전하고 긍정적이었나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "14",
               title: "이 시기, 부모님과의 관계는 원만하고 만족스러웠나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "15",
               title: "이 시기, 형제 자매는 나에게 힘이 되는 존재였나요? (외동이라면, 나를 형제 자매처럼 아껴주는 존재가 있었나요?)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "16",
               title: "친구들과 야외를 뛰어 놀거나 활동적인 놀이를 했던 기억이 있나요? (기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "17",
               title: "내가 다니던 학교에 대해서는 만족했나요? (학교에 다니지 않았다면, 동네 생활에 대해서는 만족했나요?)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "18",
               title: "이 시기에 나의 적성과 인생의 목표에 대해서 생각하고 고민했나요? (고민했던 기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: "19",
               title: "친구들과 다투었던 기억이 있나요? (기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "20",
               title: "비밀스러운 이야기를 나누던 또래 친구들이 있었나요? (그 친구들에 대한 기억이 강할수록 높은 점수)",
               score: scoreType3
            }
         ]
      },
      {
         name: '인생점수(20대)',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: "21",
               title: "마음에 맞는 연애상대를 만나 좋은 관계를 유지했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "22",
               title: "고민이나 걱정을 깊이 있게 나눌 친구가 있(었)나요? (그 친구에 대한 기억이 강할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "23",
               title: "적절한 직장을 찾아 일할 수 있게 되었나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "24",
               title: "부모님(또는 지원자)으로부터 재정적 독립을 했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "25",
               title: "내가 선택한 전공이나 직장에 대해 만족하나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "26",
               title: "해외를 여행하면서 다른 나라의 문화를 체험했나요? (체험에 대한 기억이 다양하고 좋을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: "27",
               title: "술이나 담배, 과로 등으로 인해 건강을 해치지는 않(았)나요? (건강을 해치는 경험이 많았을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "28",
               title: "건강 관리를 위해 적당한 운동이나 활동을 했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "29",
               title: "결혼이나 독립을 위한 계획과 준비를 했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "30",
               title: "학교나 직장 이외에 종교, 동아리, 취미모임 등에 규칙적으로 참여하나요?",
               score: scoreType3
            }
         ]
      },
      {
         name: '인생점수(30대)',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: '31',
               title: "결혼하여 이룬 가정에 대해 만족하나요? (미혼이라면, 현재의 가정 형태에 만족하나요?)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: '32',
               title: "금전적으로 어려움이나 갈등이 생긴 경험이 있나요? (어려움이나 갈등 경험이 많을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '33',
               title: "직장이나 그 외 내가 속한 조직에서 나를 존중하고 이해해 주었나요?",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: '34',
               title: "심신의 건강상 어려움을 겪었던 경험이 있나요? (어려움이 많을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '35',
               title: "오래된 친구들이나 동료들을 만나거나 연락을 주고 받나요?",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: '36',
               title: "불안이나 우울, 분노나 화가 폭발했던 경험이 있나요? (해당 경험이 많을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '37',
               title: "심신의 안정을 위해 정기적으로 참여하는 활동이 있나요? (종교활동, 독서모임, 자조모임, 요가 등)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '38',
               title: "정서적인 어려움이 생겼을 때, 믿고 의논할만한 상대가 있나요?",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: '39',
               title: "부모님이나 가족에게 사고, 질병 등의 어려움이 있었나요? (어려움이 많을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '40',
               title: "내가 다니는 직장이나 역할, 하고있는 일에 자부심을 느끼나요?",
               score: scoreType3
            }
         ]
      },
      {
         name: '인생점수(40대)',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: '41',
               title: "자녀를 양육하면서 행복했나요? (자녀가 없다면, 아이를 낳지 않은 선택에 만족하나요?)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '42',
               title: "부부관계(파트너와의 관계)는 만족할만 했나요? (파트너가 없는 상태라면, 그 상황에 만족했나요?)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '43',
               title: "직장에서 나의 역할이나 위치에 만족했나요? (주부라면, 가정에서 나의 역할이나 위치에 만족했나요?)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '44',
               title: "종교활동, 동아리, 취미모임 등에 정기적으로 참여하나요? (참여할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '45',
               title: "가족간에 갈등은 없었나요? (부모자녀, 형제자매 등) (잘 해결 할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '46',
               title: "가정생활을 유지하는데 적절한 유지비용을 가졌나요?",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: '47',
               title: "시력이나 치아, 머리카락 등 신체적으로 큰 변화가 있나요? (변화가 심할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: '48',
               title: "정서적으로 불안이나 걱정, 우울이 찾아오곤 했나요? (걱정이나 우울이 심할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '49',
               title: "중년으로 접어드는 시기임을 느끼며 자연스럽게 대비했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: '50',
               title: "변화되는 사회적 위치를 느끼며 알맞게 적응했나요?",
               score: scoreType3
            }
         ]
      },
      {
         name: '인생점수(50대)',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: "51",
               title: "주변 사람들과의 관계는 원만한가요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "52",
               title: "경제적으로 어려움은 없었나요? (잘 대처 했을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 1,
               questionIndex: "53",
               title: "이직, 실직, 은퇴 등의 문제로 고민한 적이 있나요? (고민이 심할수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "54",
               title: "내 인생은 성공적이었고, 만족했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "55",
               title: "호르몬의 영향으로 찾아오는 갱년기를 잘 극복했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "56",
               title: "나의 삶이 사회적으로 의미 있는 영향력을 주었나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "57",
               title: "내 인생은 나름 의미있고 가치 있었나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "58",
               title: "해결할 수 없는 부조리와 불의에 대해서는 잘 해결했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "59",
               title: "신의 믿고 의지했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "60",
               title: "내 삶은 새롭게 2막을 시작할 수 있다고 생각하나요?",
               score: scoreType3
            }
         ]
      },
      {
         name: '인생점수(60대)',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: "61",
               title: "지난 시간, 내가 살아온 인생에 만족하나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "62",
               title: "그동안 내가 만난 사람들과 행복했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "63",
               title: "내가 살아왔던 동네나 집, 주변 환경에는 만족했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "64",
               title: "나의 가족들과 친지들에 대해 만족했나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "65",
               title: "현재 나의 삶은, 이대로도 의미가 있다고 생각하나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "66",
               title: "인생을 상담하거나 조언을 구하는 후배들이 있나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "67",
               title: "가깝게 연락하고 지내는 친구나 동료가 있나요? (그들과의 관계가 안정적일수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "68",
               title: "이전에 비해 경제적으로 어려움 없이 지내고 있나요? (어려움이 없을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "69",
               title: "마음만 먹으면 지금도 새로운 일이나 관계를 해나갈 수 있다고 생각하나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "70",
               title: "건강관리와 유지는 잘 되고 있나요?",
               score: scoreType3
            }
         ]
      },
      {
         name: '인생점수(70대)',
         stpeType: 25,
         items: [
            {
               type: 0,
               questionIndex: "71",
               title: "현재 나의 건강상태에 만족하나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "72",
               title: "자산 유지와 관리, 일상을 위한 비용에는 문제가 없나요? (문제가 없을수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "73",
               title: "함께 지내온 가족이나 친지, 동료들에게 감사한가요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "74",
               title: "내 삶에 상처나 위기를 준 사람들을 이해하고 있나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "75",
               title: "나에게 주어진 모든 것들에 만족하고 감사하나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "76",
               title: "노화와 죽음을 자연스럽게 받아들이고 있나요? (받아들일수록 높은 점수)",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "77",
               title: "규칙적인 신앙생활이나 마음돌봄으로 삶에 평안을 누리고 있나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "78",
               title: "내 삶은 의미있고, 보람있는 삶이었다고 평가하나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "79",
               title: "다시 태어난다해도, 지금의 ‘나’이기를 바라나요?",
               score: scoreType3
            },
            {
               type: 0,
               questionIndex: "80",
               title: "지금 죽는다 해도 큰 후회나 미련, 원망은 없나요? (미련, 원망이 없을수록 높은 점수)",
               score: scoreType3
            }
         ]
      },
   ]);

   // 최종 검사 데이터 
   useEffect(() => {
      if (!userData.age) return; // 유저 데이터를 쿠키에서 가져오기 전이면 종료

      const userAge = Number(userData.age);
      const maxPagesByAge = userAge < 15 ? 1 : Math.min(8, Math.floor((userAge - 5) / 10) + 1);

      setFinalQuestions((prev) => {
         const updateData = prev.slice(0, maxPagesByAge); // 결과를 저장
         return updateData; // 업데이트된 배열 반환
      });

   }, [userData, currentPage]);

   // ===== 이전 버튼 클릭 했을 때 =====
   const handlePrevPage = () => {

      setIsLoading(true)
      try {
         if (currentPage <= 1) {
            alert('첫번째 페이지입니다.');
            setIsLoading(false)
            return;
         }
         setCurrentPage(prev => prev - 1) // 이전 페이지로 변경
      } catch {
         setIsLoading(false)
      }

   };

   // ===== 다음 버튼 클릭 했을 때 ===== 
   const handleNextPage = async () => {
      // 이미 진행 중인 Promise가 있으면 해당 Promise가 끝날 때까지 대기
      if (isLoading) {
         alert('잠시 후 다시 시도해 주세요')
         return;
      }
      // 새로운 작업 시작: isNexting에 Promise 저장
      setIsLoading(true)

      const currentItem = finalQuestions[currentPage - 1].items;
      const isAllChecked = currentItem.every((item) =>
         item.score.some((score) => score.checked)
      );

      try {
         if (currentPage === finalQuestions.length) {
            alert('마지막 페이지입니다.');
            setIsLoading(false);
            return;
         }
         if (!isAllChecked) {
            alert('질문에 모두 체크해주세요');
            setIsLoading(false);
            return;
         }

         if (currentPage >= finalQuestions.length) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      } catch (error) {
         console.error(error);
      } finally {
         setIsLoading(false);
      }
   };


   // ===== 새로고침 시 경고 =====
   useEffect(() => {
      const preventClose = (e) => {
         e.preventDefault();
         e.returnValue = "";
      }

      window.addEventListener("beforeunload", preventClose);
      return () => window.removeEventListener("beforeunload", preventClose);
   }, [])

   // ===== 데이터 전송 =====
   const sendData = async (isIntermediate) => {

      const saveArr = finalQuestions.reduce((acc, question) => {
         // 각 question의 items 배열을 순회하여 checked가 true인 항목 찾기
         question.items.forEach((item) => {
            item.score.forEach((scoreItem) => {
               if (scoreItem.checked) {
                  acc.push({
                     order_number: parseInt(item.questionIndex, 10),
                     value: scoreItem.number,
                     type: item.type
                  });
               }
            })
         });
         return acc;
      }, []);

      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/inspect/life/complete`, {
            birth: userData.birth,
            saveArr: saveArr,
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         setResultPageData(response);
         isIntermediate === 'IntermediateSave' && alert('중간 저장 되었습니다.')

         return true;
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)

         return false;
      }
   }

   // 마지막 페이지 UI 조작
   const [lastPage, setLastPage] = useState(false);
   useEffect(() => {
      if (!finalQuestions) return;

      const maxPage = finalQuestions.length;

      if (currentPage >= maxPage) {
         setLastPage(true);
      } else {
         setLastPage(false);
      }
   }, [finalQuestions, currentPage])

   // 검사완료 버튼 클릭 함수
   const scrollToTop = () => {
      const container = document.querySelector('.test-content.insight-test');
      container.scrollTop = 0;
      window.scrollTo(0, 0);
   }
   const handleTestDone = async () => {

      scrollToTop();
      setIsLoadingVisible(true);
      const sendDataResult = await sendData();

      if (!sendDataResult) return;
   }


   // (개발용) 1번으로 모두 체크
   function randomInt(min, max) {
      var randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
      return randomNum;
   }
   const handleAllCheckToOne = () => {
      setFinalQuestions((prev) => {
         const updateData = [...prev.data]

         updateData[prev.currentPage - 1].items.forEach((item) => {
            const randomNum = randomInt(0, item.score.length - 1)
            item.score.forEach((scoreItem, index) => {
               if (index === randomNum) {
                  scoreItem.checked = true;
               } else {
                  scoreItem.checked = false;
               }
            });
         });

         return { currentPage: prev.currentPage, data: updateData }
      })
   }

   const setPdfDownloadStart = useSetRecoilState(LifeScorePdfDownloadStartAtom);
   if (resultPageData) {
      setPdfDownloadStart({
         start: true,
         birth: userData.birth,
         resultPageData: resultPageData.data.data
      })
   }

   // =====================================================================
   if (!finalQuestions) return <Loading />;

   return (
      <section className="col-group section enter-answer">
         <div className="side">
            <div className="side-step-list row-group">
               <div className='active side-step col-group'>
                  <i className="icon"></i>
                  <p className="title">Step 01</p>
               </div>
               <div className='use-to-dev'>
                  <p className='title'>개발 완료 후 삭제</p>
                  <div className='btn-wrap'>
                     <button
                        className='btn'
                        onClick={handleAllCheckToOne}
                     >
                        랜덤으로 모두 체크
                     </button>
                     <button
                        className='btn btn-next'
                        onClick={handleNextPage}
                     >
                        다음
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div className="content test-content insight-test">
            <div className="test-title-wrap col-group">
               <div className="test-title-group col-group">
                  <p className="txt bold">Step 01</p>
                  <p className="txt">
                     {currentPage > 0 && currentPage <= finalQuestions.length && finalQuestions[currentPage - 1].name}
                  </p>

               </div>
               <div className="page col-group">
                  <p className="txt bold">{currentPage}</p>
                  <p className="txt">/</p>
                  <p className="txt">{finalQuestions.length}</p>
               </div>
               <div className="gauge">
                  <div className="gauge-bar" style={{ width: `calc( ( 100% / ${finalQuestions.length} ) * ${currentPage} )` }}></div>
               </div>
            </div>
            <div className="test-wrap">
               {!isLoading && finalQuestions ?
                  <TestList
                     key={currentPage}
                     currentPage={currentPage}
                     finalQuestions={finalQuestions}
                     setFinalQuestions={setFinalQuestions}
                  /> :
                  <Loading />
               }
               {!isLoading &&
                  <div className="form-footer">
                     <button onClick={handlePrevPage} className="form-footer-btn">이전</button>
                     {!lastPage ? (
                        <button onClick={handleNextPage} className="form-footer-btn">다음</button>
                     ) : (
                        <button onClick={handleTestDone} className="form-footer-btn white">완료</button>
                     )}
                  </div>
               }
            </div>
         </div>
         {isLoadingVisible &&
            <LongTimeLoading />
         }
      </section>
   );
};

const LifeScoreEnterAnswer = () => {
   return (
      <CommonUi
         isLifeScore={true}
         isAnswerPage={true}
         content={<Content />}
         mainStep={2}
      />
   );
};

export default LifeScoreEnterAnswer;

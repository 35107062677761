import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { PdfProgressAtom } from '../../recoil/PdfDownloadAtom'

const LongTimeLoading = ({ waitingTime }) => {
   const pdfDonwloadValue = useRecoilValue(PdfProgressAtom)

   useEffect(() => {
      var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

      function preventDefault(e) {
         e.preventDefault();
      }

      function preventDefaultForScrollKeys(e) {
         if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
         }
      }

      var supportsPassive = false;
      try {
         window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
            get: function () { supportsPassive = true; }
         }));
      } catch (e) { }

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

      function disableScroll() {
         window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
         window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
         window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
         window.addEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      function enableScroll() {
         window.removeEventListener('DOMMouseScroll', preventDefault, false);
         window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
         window.removeEventListener('touchmove', preventDefault, wheelOpt);
         window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      // modal이 떠 있을 땐 스크롤 막음
      disableScroll();

      // modal 닫히면 다시 스크롤 가능하도록 함
      return () => enableScroll();

   }, [])

   return (
      <div className='loading-modal-wrap'>
         <div className='content'>
            <i className='xi-spinner-1 xi-spin icon-spin'></i>
            <p className='text'>잠시만 기다려주세요!</p>
            {waitingTime && <p style={{ fontSize: '14px', color: '#9a9a9c' }}>예상 대기 시간: {waitingTime}</p>}
            {/* 진행률 업데이트가 시작되면 */}
            {pdfDonwloadValue > 0 && <p className='percent'>{pdfDonwloadValue}%</p>}
         </div>
      </div>
   )
}

export default LongTimeLoading
import React from 'react'
import Loading from '../../common/Loading'

const ProductUseList = ({ listData }) => {
   return (
      <tbody>
         {listData ? (
            listData.list.data.length > 0 ?
               listData.list.data.map((listItem, index) => (
                  <tr key={index}>
                     {listItem.inspect_type !== 2 &&
                        <td>{listItem.inspect_type === 0 ? '사용' : listItem.inspect_type === 1 ? '선물' : ''}</td>
                     }
                     <td>{listItem.name}</td>
                     <td>{listItem.phone}</td>
                     <td>{listItem.email}</td>
                     <td>{listItem.inspect_name}</td>
                     <td>{listItem.format_created_at}</td>
                  </tr>
               ))
               : <tr>
                  <td colSpan="6">
                     <div className="null-txt row-group">
                        <i className="icon"></i>
                        내용이 존재하지 않습니다.
                     </div>
                  </td>
               </tr>
         ) : (
            <Loading />
         )}
      </tbody>
   )
}

export default ProductUseList
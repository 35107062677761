import DOMPurify from 'dompurify'
import React from 'react'

import { images } from '../../../../assets/testPageImages'

const ResultScoreTextWrap = ({ scoreType, DetailText, isDigital }) => {
   return (
      <div className="result-score-txt-wrap row-group gap40">
         <div className={`result-score-txt ${scoreType}`} >
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(DetailText.title) }} />
            {!isDigital ?
               <img
                  className='result-score-txt-img'
                  src={scoreType === 'type1' ? images.face_1 : scoreType === 'type2' ? images.face_2 : scoreType === 'type3' ? images.face_3 : images.face_4}
                  alt=''
               />
               : <img
                  className='result-score-txt-img'
                  src={scoreType === 'type1' ? images.face_1 : scoreType === 'type2' ? images.face_5 : images.face_6}
                  alt=''
               />
            }
         </div>
         <p className="default-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(DetailText.desc) }} />
      </div>
   )
}

export default ResultScoreTextWrap
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilState } from "recoil";
import axios from "axios";
import { useEffect, useState } from "react";
import AccessToken from "./AccessToken";
import { authState, LoginInfoAtom } from "../../recoil/LoginInfoAtom";
import FetchLogout from "./FetchLogout";

const useConfirmToken = () => {

   const navigate = useNavigate();
   const { pathname } = useLocation();
   const accessToken = AccessToken();
   const setIsAuthenticated = useSetRecoilState(authState)
   const setLoginInfoAtom = useSetRecoilState(LoginInfoAtom);


   const fetchTokenData = async () => {
      if (!accessToken) {
         setIsAuthenticated(false);
         setLoginInfoAtom(false); // 로그인 정보 초기화
         return;
      }

      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/token/check`, {}, {
            headers: {
               Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true,
         });

         setIsAuthenticated(true);

         // 로그인 정보 업데이트
         setLoginInfoAtom((prev) => ({
            ...prev,
            id: response.data.data.id,
            member_type: response.data.data.member_type,
            login_type: response.data.data.login_type,
            identified: response.data.data.identified,
            name: response.data.data.name,
            phone: response.data.data.phone,
            birth: response.data.data.birth,
         }));
      } catch (error) {
         setIsAuthenticated(false);
         setLoginInfoAtom(false); // 로그인 정보 초기화
         console.error(error);

         if (error.response?.status === 400) {
            alert("유효하지 않은 토큰입니다. \n새로고침 후 다시 로그인해 주세요.");
            navigate('/')
            window.location.reload();
         }
      }
   };

   useEffect(() => {
      fetchTokenData();
   }, [accessToken, pathname, setIsAuthenticated, setLoginInfoAtom]);

   // return tokenData; // 필요하면 토큰 데이터를 반환
};

export default useConfirmToken;
import React from 'react'

const Loading = () => {
   return (
      <div className='loading-wrap'>
         <i className='xi-spinner-1 xi-spin icon-spin' />
      </div>
   )
}

export default Loading;
import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil';
import { LoginInfoAtom } from '../../recoil/LoginInfoAtom';
import { Outlet, useNavigate } from 'react-router-dom';
import Loading from '../../components/common/Loading';

const GeneralUserPage = () => {

   const navigate = useNavigate();
   const userInfo = useRecoilValue(LoginInfoAtom);

   useEffect(() => {
      if (!userInfo) return;

      if (userInfo.member_type === 1) {
         alert('개인회원 전용 페이지입니다.')
         navigate("/", { replace: true });
         return;
      }
   }, [userInfo])

   return userInfo ? <Outlet /> : <Loading />;
}

export default GeneralUserPage
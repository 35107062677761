import axios from 'axios';
import React, { useEffect, useState } from 'react'
import AccessToken from '../../../hooks/token/AccessToken';
import { ShowErrorMsg } from '../../../utils/ShowErrorMsg';
import Loading from '../../../components/common/Loading';
import SnsAgreement from '../../../components/user/SnsAgreement';

// Hooks
import { currentVerificationStep, getVerificationCode, handleVerificationCode, inputPhoneNum, verifyAuth } from '../../../hooks/VerificationCode';
import { ShowSuccessMsg } from '../../../utils/ShowSuccessMsg';
import { Link } from 'react-router-dom';

const AccountManagement = () => {

   const accessToken = AccessToken();

   const [marketingTypeArr, setMarketingTypeArr] = useState({
      option: false,
      optionSMS: false,
      optionEmail: false,
      optionTel: false,
   });

   const [userInfo, setUserInfo] = useState({
      isEditPhone: false,
      // phone: '',
   })

   // ===== 데이터 로딩 =====
   const [isLoading, setIsLoading] = useState(true);

   // ===== 데이터 fetch =====
   const fetchData = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/mypage/account/detail`, {}, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })

         const localIsCounselor = response.data.data.member_type === 1

         setUserInfo((prev) => ({
            ...prev,
            member_type: response.data.data.member_type,
            login_type: response.data.data.login_type,
            identified: response.data.data.identified,
            name: response.data.data.name,
            phone: response.data.data.phone,
            ...(localIsCounselor && { birth: response.data.data.birth }),
            ...(localIsCounselor && { counsel_type: response.data.data.counsel_type }),
            ...(localIsCounselor && { intro_text: response.data.data.format_intro_text }),
            ...(localIsCounselor && { brief_history: response.data.data.brief_history.map((item) => ({ value: item.content })) }),
            marketing: response.data.data.marketing,
            marketing_type: response.data.data.marketing_type,
         }))
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
      } finally {
         setIsLoading(false)
      }
   }

   // ===== 유저 정보 입력 =====
   const infoInputChange = (e) => {
      const { name, value } = e.target;

      // 휴대폰 번호를 입력했을 때 step1로 변경
      if (name === 'phone') {
         inputPhoneNum(userInfo, setVerificationStep);
      }

      setUserInfo((prev) => ({
         ...prev,
         [name]: value,
         ...(name === 'phone' && { isEditPhone: true }),
      }));
   };


   // ===== 휴대폰 인증 ======
   const [verificationStep, setVerificationStep] = useState(3);
   // 인증번호
   const [verificationCode, setVerificationCode] = useState("");

   // ===== 상담 분야 =====
   const CounselTypeInfo = [
      { name: "심리적 증상(우울, 불안, 분노 등)" },
      { name: "성격 및 자기 이해" },
      { name: "가족 관계" },
      { name: "대인관계" },
      { name: "직장 생활" },
      { name: "학업 진로" },
      { name: "중독 및 섭식장애" },
      { name: "정서 및 행동 문제" },
      { name: "자녀양육" },
      { name: "또래 관계" },
      { name: "성문제" },
      { name: "기타(직접입력)" },
   ];

   const [isCheckedEtc, setIsCheckedEtc] = useState();


   const [etcValue, setEtcValue] = useState('');

   // 상담 분야 체크박스 onChange시
   const handleCounselType = (e) => {
      const { name, checked } = e.target;

      if (name === "기타(직접입력)") {
         setIsCheckedEtc(checked)
         setUserInfo((prev) => {
            let updatedCounselType = prev.counsel_type;

            if (checked) {
               updatedCounselType.push('etcText');
            } else {
               updatedCounselType = updatedCounselType.filter((item) =>
                  CounselTypeInfo.some((infoItem) => item === infoItem.name)
               );
            }


            return {
               ...prev,
               counsel_type: updatedCounselType
            }
         })
      } else {
         setUserInfo((prev) => {
            let updatedCounselType = prev.counsel_type;

            if (checked) {
               // 체크된 경우 해당 name 추가
               updatedCounselType.push(name);
            } else {
               // 체크 해제된 경우 해당 name 제거
               updatedCounselType = updatedCounselType.filter(
                  (item) => item !== name
               );
            }

            return {
               ...prev,
               counsel_type: updatedCounselType
               // counsel_type: updatedCounselType.join("&nbsp;"),
            };
         });
      }
   };

   // 기타 직접 입력 내용 변수에 저장
   const handleEtcValue = (e) => {
      const value = e.target.value;
      setEtcValue(value);
   };
   const saveEtcValue = () => {
      const isEtcValue = etcValue === '' ? false : true

      // 기타입력에 적은 내용이 없으면 함수 종료
      if (isCheckedEtc && !isEtcValue) {
         alert('기타의 내용을 입력해 주세요.')
         return false;
      }
      if (!isCheckedEtc) {
         setEtcValue('')
      }
   };
   // ==============


   // ===== 주요 약력 =====
   const [majorHistoryInput, setMajorHistoryInput] = useState([]);

   const handleAddMajorHistory = () => {
      if (majorHistoryInput.length < 4) {
         setMajorHistoryInput([...majorHistoryInput, { value: "" }]);
      } else {
         alert("최대 5개까지 입력 가능합니다");
      }
   };

   // 현재 수정중인 input의 index와 데이터 배열에 있는 index가 일치하면, value를 현재 value로 저장
   // 그렇지 않으면 기존 아이템을 그대로 반환
   const handleInputChange = (index, event) => {
      const newInputValues = majorHistoryInput.map((item, i) => (i === index ? { value: event.target.value } : item));
      setMajorHistoryInput(newInputValues);
   };

   // 클릭한 요소 삭제
   const handleDelMajorHistory = (index) => {
      setMajorHistoryInput((prev) => prev.filter((_, i) => i !== index));
   };

   // ===== 비밀번호 변경 =====
   const [toChangePw, setToChangePw] = useState('');
   const changePw = (e) => {
      const currentValue = e.target.value;
      setToChangePw(currentValue)
   }
   const fetchChangePwApi = async () => {

      if (window.confirm('비밀번호를 정말 변경하시겠습니까?')) {
         try {
            await axios.post(`${process.env.REACT_APP_API_URL}/mypage/account/change/pwd`, {
               password: toChangePw
            }, {
               headers: {
                  Authorization: `Bearer ${accessToken}`
               }
            })
            alert('비밀번호가 성공적으로 변경 되었습니다.')
            window.location.href = '/';
         } catch (error) {
            console.error(error)
            ShowErrorMsg(error)
         }
      }
   }

   // ===== Send Data =====
   const sendData = async () => {

      const saveEtcValueResult = saveEtcValue()
      if (saveEtcValueResult === false) {
         return
      }

      let finalUserInfo = { ...userInfo };

      if (finalUserInfo.member_type === 1) {
         // 상담분야
         finalUserInfo.counsel_type = finalUserInfo.counsel_type.map(item =>
            item === 'etcText' ? etcValue : item
         );
         finalUserInfo.counsel_type = finalUserInfo.counsel_type.join('&nbsp;');

         // 주요 약력
         finalUserInfo.brief_history = finalUserInfo.brief_history.map((item) => item.content)
         let ElValue = [];

         const majorHistoryEl = document.querySelectorAll(".major-history");
         majorHistoryEl.forEach((element, index) => {
            ElValue[index] = element.value;
         });

         finalUserInfo.brief_history = ElValue
      }

      // 마케팅 세부 사항
      finalUserInfo.marketing_type = finalUserInfo.marketing_type.join('&nbsp;')

      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/mypage/account/update`, {
            name: finalUserInfo.name,
            ...(finalUserInfo.isEditPhone && { phone: finalUserInfo.phone }),
            ...(isCounselor && { birth: finalUserInfo.birth }),
            ...(isCounselor && { counsel_type: finalUserInfo.counsel_type }),
            ...(isCounselor && { intro_text: finalUserInfo.intro_text }),
            ...(isCounselor && { briefHistoryArr: finalUserInfo.brief_history }),
            marketing: finalUserInfo.marketing,
            marketing_type: finalUserInfo.marketing_type,
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         ShowSuccessMsg(response)
         window.location.reload();
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
      }
   }

   useEffect(() => {
      fetchData();
   }, [])

   // ===== userInfo 불러오고 난 후 =====
   useEffect(() => {
      if (isLoading) return;

      const counselType = userInfo.counsel_type || []; // 비어있으면 빈 배열 사용
      const etcValueResult = counselType.filter(
         (item) => !CounselTypeInfo.some((info) => info.name === item)
      );

      // 주요 약력 초기값 변경
      setMajorHistoryInput(userInfo.brief_history)

      // 상담분야 기타(직접입력) 초기값 변경
      setIsCheckedEtc(() => {
         if (counselType.length === 0) {
            return false; // counsel_type이 없거나 비어 있으면 false
         }
         const hasMismatch = !counselType.every(item =>
            CounselTypeInfo.some(info => info.name === item)
         );

         return hasMismatch; // 모든 항목이 일치하면 false, 하나라도 불일치하면 true
      })

      // 마케징 수신동의 초기값 변경
      setMarketingTypeArr((prev) => ({
         ...prev,
         option: userInfo.marketing,
         optionSMS: userInfo.marketing_type?.some((item) => item === 'SMS/알림톡'),
         optionEmail: userInfo.marketing_type?.some((item) => item === '이메일'),
         optionTel: userInfo.marketing_type?.some((item) => item === '전화'),
      }))

      setEtcValue(etcValueResult)

   }, [isLoading])

   // ===== 마케팅 체크 할 때 userInfo 업데이트 =====
   useEffect(() => {
      const marketingTypeResult = [
         marketingTypeArr.optionEmail && '이메일',
         marketingTypeArr.optionSMS && 'SMS/알림톡',
         marketingTypeArr.optionTel && '전화',
      ].filter(Boolean);

      setUserInfo((prev) => ({
         ...prev,
         marketing: marketingTypeArr.option ? 1 : 0,
         marketing_type: marketingTypeResult
      }))
   }, [marketingTypeArr])

   // ===== 회원탈퇴 관련 =====
   const ReasonWithdrawal = [
      '원하는 프로그램이 없음',
      '탈퇴 후 재가입을 위해서',
      '개인정보 누출 우려',
      '프로그램의 다양성/품질 불만',
      '프로그램 가격 불만',
      '환불 불만',
      '사후조치 불만',
      '기타(직접입력)',
   ]

   const [selectedOption, setSelectedOption] = useState('');
   const [otherInput, setOtherInput] = useState('');
   const [isVisibleWithdrawal, setIsVisibleWithdrawal] = useState(false);
   const [requiredChecked, setRequiredChecked] = useState(false);

   const handleOptionChange = (e, currentItem) => {
      const { name, checked } = e.target;

      if (currentItem !== '기타(직접입력)' && currentItem !== 'agree_leave') {
         setOtherInput(''); // 기타 선택이 해제되면 입력값 초기화
      }

      if (name === 'agree_leave') {
         setRequiredChecked(checked)
      } else {
         setSelectedOption(currentItem);
      }
   };

   const handleOtherChange = (e) => {
      setOtherInput(e.target.value);
   };

   const sendReasonWithdrawal = async (e) => {
      e.preventDefault();

      const isOther = selectedOption === '기타(직접입력)'

      if (isOther && otherInput === '') {
         alert('기타(직접입력)의 내용을 입력해 주세요.')
         return;
      }

      if (!selectedOption) {
         alert('탈퇴 사유를 선택해 주세요.')
         return;
      }

      if (!requiredChecked) {
         alert('필수 항목에 동의해 주세요.')
         return;
      }

      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/mypage/account/leave`, {
            leave_msg: isOther ? otherInput : selectedOption
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         ShowSuccessMsg(response)
         window.location.href = '/'
      } catch (error) {
         console.error(error);
         ShowErrorMsg(error);
      }
   };


   if (isLoading) return <Loading />

   // 계산
   const isCounselor = userInfo.member_type === 1
   const memberType = userInfo.member_type
   const certType = 4 // 0: 일반 회원가입, 1: 소셜 회원가입, 2: 아이디 찾기, 3: 비밀번호 찾기, 4: 핸드폰 번호 변경

   return (
      <>
         <div className="myPageContent">
            <div className="mp-main-title-wrap border">
               <h2 className="mp-main-title">
                  <span className="mb">My 정보 <i></i></span>
                  계정 정보 관리
               </h2>
            </div>
            {/* 상담사 회원 정보 관리 */}
            <div className="form-wrap account-form-wrap row-group">
               <div className="form-list row-group border">
                  {isCounselor &&
                     <div className="form-item">
                        <p className="account-guide-txt">
                           ※ 가입시 입력하신 <span className="red">자격증/학과 정보</span>의 변경을 원하신다면
                           <Link to="/my-page/inquiry" className="link blue">1:1 문의</Link>를 이용해 주세요.
                        </p>
                     </div>
                  }
                  <div className="form-item">
                     {userInfo.login_type === 1 &&
                        <div className="sns-icon naver"></div>
                     }
                     {userInfo.login_type === 2 &&
                        <div className="sns-icon kakao"></div>
                     }
                  </div>

                  <div className="form-item row-group">
                     <div className="item-default">
                        이메일
                        <span className="red">*</span>
                     </div>
                     <div className="item-user">
                        <input type="text" className="form-input disable cursor-default" value={userInfo.identified} disable />
                     </div>
                  </div>
                  {userInfo.login_type === 0 &&
                     <div className="form-item row-group">
                        <div className="item-default">
                           비밀번호
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <div className="form-btn-wrap col-group">
                              <input
                                 onChange={changePw}
                                 value={toChangePw}
                                 type="text"
                                 className="form-input"
                                 placeholder='변경할 비밀번호를 입력해 주세요.'
                              />
                              <button
                                 onClick={fetchChangePwApi}
                                 className="form-btn"
                              >
                                 비밀번호 변경
                              </button>
                           </div>
                        </div>
                     </div>
                  }
                  <div className="form-item row-group">
                     <div className="item-default">
                        이름
                        <span className="red">*</span>
                     </div>
                     <div className="item-user">
                        <input
                           onChange={infoInputChange}
                           name='name'
                           value={userInfo.name}
                           type="text"
                           className="form-input"
                        />
                     </div>
                  </div>
                  <div className="form-item row-group">
                     <div className="item-default">
                        휴대폰 번호
                        <span className="red">*</span>
                     </div>
                     <div className="item-user">
                        <div className="form-btn-wrap col-group">
                           <input
                              onChange={infoInputChange}
                              value={userInfo.phone}
                              name='phone'
                              type="text"
                              className="form-input"
                              placeholder='변경할 휴대폰 번호를 입력해 주세요.'
                           />
                           <button
                              onClick={getVerificationCode(
                                 memberType,
                                 verificationStep,
                                 setVerificationStep,
                                 certType,
                                 userInfo
                              )}
                              className={`${currentVerificationStep(verificationStep).className} form-btn`}
                           >
                              {currentVerificationStep(verificationStep).text}
                           </button>
                        </div>
                        {/* 인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                        <div className={`${verificationStep === 2 ? "" : "hide-g"} form-input-wrap row-group`} style={{ marginTop: '8px' }}>
                           <div className="form-btn-wrap col-group">
                              <input
                                 onChange={handleVerificationCode(setVerificationCode)}
                                 value={verificationCode}
                                 type="number"
                                 className="form-input"
                                 placeholder="인증번호"
                              />
                              <button
                                 onClick={verifyAuth(memberType, certType, userInfo, verificationCode, setVerificationStep)}
                                 className={`${verificationCode !== "" ? "" : "disable"} form-btn `}
                              >
                                 인증확인
                              </button>
                           </div>

                           {/* //인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                           <p className="guide-txt red">휴대폰으로 발송된 인증번호를 입력해주세요.</p>
                        </div>
                     </div>
                  </div>
                  {isCounselor &&
                     <>
                        <div className="form-item row-group">
                           <div className="item-default">
                              생년월일
                              <span className="red">*</span>
                           </div>
                           <div className="item-user">
                              <input
                                 onChange={infoInputChange}
                                 value={userInfo.birth}
                                 name='birth'
                                 type="date"
                                 className="form-date"
                              />
                           </div>
                        </div>
                        <div className="form-item row-group">
                           <div className="item-default">
                              상담 분야
                              <span className="red">*</span>
                              <p className="guide-txt">
                                 중복선택이 가능합니다.
                              </p>
                           </div>
                           <div className="item-user">
                              <div className="form-label-wrap half col-group">
                                 {CounselTypeInfo.map((item, index) => (
                                    <label htmlFor={`field_0${index + 1}`} key={index}>
                                       <input
                                          onChange={handleCounselType}
                                          checked={
                                             item.name === '기타(직접입력)' ? (
                                                isCheckedEtc
                                             ) : (
                                                userInfo.counsel_type.some((dateItem) => dateItem === item.name)
                                             )
                                          }
                                          name={item.name}
                                          type="checkbox"
                                          className="form-checkbox"
                                          id={`field_0${index + 1}`}
                                       />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">{item.name}</p>
                                       </div>
                                    </label>
                                 ))}
                                 {isCheckedEtc && (
                                    <input
                                       onChange={handleEtcValue}
                                       value={etcValue}
                                       name="기타(직접입력)"
                                       type="text"
                                       className="form-input"
                                       placeholder="기타 내용을 입력해주세요"
                                    />
                                 )}
                              </div>
                           </div>
                        </div>
                        <div className="form-item row-group">
                           <div className="item-default">
                              소개 문구
                              <span className="red">*</span>
                           </div>
                           <div className="item-user">
                              <div className="form-textarea-wrap">
                                 <textarea
                                    onChange={infoInputChange}
                                    value={userInfo.intro_text}
                                    name="intro_text"
                                    className="form-textarea h200"
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="form-item row-group">
                           <div className="item-default">
                              주요 약력
                              <span className="red">*</span>
                              <p className="guide-txt">
                                 최대 5개까지 입력 가능하며, 입력할 내용이 없는 경우 '없음'이라고 입력해 주세요.
                              </p>
                           </div>
                           <div className="item-user">
                              <div className="form-input-add-wrap row-group">
                                 {/* <div className="form-input-add col-group">
                                 <input type="text" className="form-input major-history" placeholder="약력" />
                                 <button onClick={() => handleAddMajorHistory()} className="btn add-btn why">
                                    <i></i>
                                 </button>
                              </div> */}
                                 {majorHistoryInput.map((item, index) => (
                                    index === 0 ? (
                                       <div className="form-input-add col-group">
                                          <input
                                             value={item.value}
                                             onChange={(event) => handleInputChange(index, event)}
                                             name="majorHistory"
                                             type="text"
                                             className="form-input major-history"
                                             placeholder="약력"
                                          />
                                          <button onClick={() => handleAddMajorHistory()} className="btn add-btn">
                                             <i></i>
                                          </button>
                                       </div>
                                    ) : (
                                       <div className="form-input-add col-group" key={index}>
                                          <input
                                             value={item.value}
                                             onChange={(event) => handleInputChange(index, event)}
                                             name="majorHistory"
                                             type="text"
                                             className="form-input major-history"
                                             placeholder="약력"
                                          />
                                          <button onClick={() => handleDelMajorHistory(index)} className="btn del-btn">
                                             <i></i>
                                          </button>
                                       </div>
                                    )
                                 ))}
                              </div>
                           </div>
                        </div>
                     </>
                  }
               </div>

               <div className="form-item row-group">
                  <div className="item-default">
                     마케팅 정보 수신 동의
                  </div>
                  <div className="item-user">
                     <div className="join-agree-wrap row-group">
                        <SnsAgreement
                           marketingTypeArr={marketingTypeArr}
                           setMarketingTypeArr={setMarketingTypeArr}
                        />
                     </div>
                  </div>
               </div>

               <div className="mp-section-footer border">
                  <button
                     onClick={sendData}
                     type='button'
                     className="form-footer-btn"
                  >
                     정보저장
                  </button>
                  <button
                     onClick={() => setIsVisibleWithdrawal(true)}
                     type='button'
                     className="form-footer-leave-btn"
                  >
                     회원탈퇴
                  </button>
               </div>
            </div>
         </div>
         {isVisibleWithdrawal &&
            <div className="modal-container">
               <div className="modal-wrap">
                  <i
                     onClick={() => setIsVisibleWithdrawal(false)}
                     className="xi-close close-btn pc"
                  />
                  <i
                     onClick={() => setIsVisibleWithdrawal(false)}
                     className="xi-close close-btn mb"
                  />
                  <div className="modal-title-wrap center border">
                     <p className="modal-title">
                        회원탈퇴
                     </p>
                     <p className="modal-title-txt">
                        의견을 남겨주시면 소중히 새겨듣고 <br />
                        오래 이용할 수 있는 서비스로 개선하겠습니다.
                     </p>
                  </div>

                  <div className="modal-scroll-wrap">
                     <div className="form-item row-group">
                        <div className="item-default bold">
                           탈퇴 사유 선택
                        </div>
                        <div className="item-user">
                           <div className="form-label-wrap row-group">
                              {ReasonWithdrawal.map((item, index) =>
                                 <label for={`reason_0${index}`}>
                                    <input
                                       onChange={(e) => handleOptionChange(e, item)}
                                       id={`reason_0${index}`}
                                       type="radio"
                                       className="form-radio"
                                       name="reason"
                                    />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       {item === '기타(직접입력)' ? (
                                          <div className="txt">
                                             {item}
                                             {selectedOption === '기타(직접입력)' &&
                                                <input
                                                   onChange={handleOtherChange}
                                                   value={otherInput}
                                                   disabled={selectedOption !== '기타(직접입력)'}
                                                   className="form-textarea"
                                                   placeholder="탈퇴 사유를 입력해주세요"
                                                />
                                             }
                                          </div>
                                       ) : (
                                          <p className="txt">
                                             {item}
                                          </p>
                                       )}
                                    </div>
                                 </label>
                              )}
                           </div>
                        </div>
                     </div>
                     <div className="modal-guide-txt-wrap">
                        <div className="modal-guide-txt-list row-group">
                           <p className="modal-guide-txt">
                              보유하고 있던 <span className="red">구매 이력, 검사결과</span> 등은 모두 소멸되고 복구가 불가능합니다. 단, [개인정보보호법, 전자상거래법] 등 기타 법령에서 요구하는 정보는 해당 법령에서 요구하는 기간 동안 보존합니다.
                           </p>
                        </div>
                        <div className="join-agree-item col-group">
                           <label for="agree_leave">
                              <input
                                 onChange={(e) => handleOptionChange(e, 'agree_leave')}
                                 value={requiredChecked}
                                 type="checkbox"
                                 className="form-checkbox"
                                 id="agree_leave"
                                 name='agree_leave'
                              />
                              <div className="checked-item col-group">
                                 <div className="icon">
                                    <i className="xi-check"></i>
                                 </div>
                                 <p className="txt">
                                    (필수) 위 내용을 모두 확인했습니다.
                                 </p>
                              </div>
                           </label>
                        </div>
                     </div>
                  </div>

                  <div className="modal-footer col-group">
                     <button
                        onClick={sendReasonWithdrawal}
                        type='button'
                        className="modal-footer-btn gray"
                     >
                        회원탈퇴
                     </button>
                     <button
                        onClick={() => setIsVisibleWithdrawal(false)}
                        className="modal-footer-btn green"
                     >
                        계속 이용하기
                     </button>
                  </div>
               </div>
            </div>
         }
      </>
   )
}

export default AccountManagement
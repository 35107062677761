import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AccessToken from '../../../../hooks/token/AccessToken'
import Loading from '../../../../components/common/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ShowErrorMsg } from '../../../../utils/ShowErrorMsg';

const PurchaseDetailPage = ({ pageType }) => {

   const navigate = useNavigate();
   const { id } = useParams();

   const accessToken = AccessToken();
   const [detailPageData, setDetailPageData] = useState();

   // 최종 환불 금액 labels
   const finalRefundCostLabels = {
      2: '',
      3: detailPageData?.refund_money,
      4: '환불반려',
   }

   // ===== API Type =====
   const apiType = {
      0: {
         apiUrl: 'purchase',
         dataUrl: {
            created_at: detailPageData?.detail?.format_created_at,
            order_number: detailPageData?.detail?.order_number,
            product_name: detailPageData?.detail?.product_name,
            total_product_cost: detailPageData?.total_product_price,
         },
         title: '구매내역',
         finalInfo: {
            title: '최종 결제 정보',
            items: [
               {
                  name: '상품 합계',
                  dataUrl: detailPageData?.total_product_price,
                  color: 'normal',
               },
               {
                  name: '상품 할인',
                  dataUrl: detailPageData?.total_product_discount,
                  color: 'red',
               },
               {
                  name: '환불 금액',
                  dataUrl: detailPageData?.total_refund_price,
                  color: 'red',
               },
               {
                  name: '최종 결제 금액',
                  dataUrl: detailPageData?.final_total_price,
                  color: 'blue',
                  isTotalCost: true,
               },
            ]
         }
      },
      1: {
         apiUrl: 'refund',
         dataUrl: {
            created_at: detailPageData?.info?.format_created_at,
            order_number: detailPageData?.info?.order_number,
            product_name: detailPageData?.info?.product_name,
            total_product_cost: detailPageData?.final_option_price,
         },
         title: '환불내역',
         finalInfo: {
            title: '최종 환불 내역',
            items: [
               {
                  name: '환불요청일',
                  dataUrl: detailPageData?.format_refund_date,
                  color: 'normal',
               },
               {
                  name: '환불사유',
                  dataUrl: detailPageData?.refund_content,
                  color: 'normal',
               },
               {
                  name: '요청처리일',
                  dataUrl: detailPageData?.format_refund_complete_date,
                  color: 'normal',
               },
               {
                  name: '결제 금액',
                  dataUrl: detailPageData?.final_option_price,
                  color: 'normal',
               },
               {
                  name: '최종 환불 금액',
                  dataUrl: finalRefundCostLabels[detailPageData?.state],
                  color: 'blue',
                  isTotalCost: true,
               },
            ]
         }
      }
   }

   const fetchData = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/mypage/${apiType[pageType].apiUrl}/detail`, {
            id: id
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         setDetailPageData(response.data.data);
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
      }
   }

   useEffect(() => {
      // id가 없을 때 메인으로
      if (!id) {
         alert('잘못된 접근입니다.');
         navigate('/my-page/purchase/history');
         return;
      }
      fetchData();
   }, [])

   // 상태 labels
   const stateLabels = {
      1: '결제완료',
      2: '환불요청',
      3: '환불완료',
      4: '환불반려',
   };

   if (!detailPageData) return <Loading />

   return (
      <div className="myPageContent purchase-detail mypage_detail">
         <div className="mp-main-title-wrap col-group">
            <h2 className="mp-main-title">
               <span className="mb">My 쇼핑 <i></i></span>
               {apiType[pageType].title} 상세
            </h2>
            <div className="mp-main-txt-group col-group">
               <p className="mp-main-txt">
                  <strong>
                     {apiType[pageType].dataUrl.created_at} 주문
                  </strong>
               </p>
               <p className="mp-main-txt">
                  주문번호 {apiType[pageType].dataUrl.order_number}
               </p>
            </div>
         </div>

         <div className="mp-section-wrap row-group">
            <div className="mp-section">
               <div className="table-wrap prod-table-wrap">
                  <table className="prod-table">
                     <colgroup>
                        <col width="60%" />
                     </colgroup>
                     <thead>
                        <tr>
                           <th className="left">
                              상품정보
                           </th>
                           <th className='hide'>
                              수량
                           </th>
                           <th>
                              결제금액
                           </th>
                           <th>
                              상태
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {pageType === 0 ?
                           detailPageData.detail.products.map((product, index) =>
                              <tr key={index}>
                                 <td>
                                    <div className="prod-table-item col-group">
                                       <div className="img-box">
                                          <img src={detailPageData.detail.img.file_addr} alt="상품 썸네일" />
                                       </div>
                                       <div className="txt-wrap row-group">
                                          <p className="prod-item-label">
                                             {detailPageData.detail.type === 0 ? 'Mind Insight' : 'Mind Therapy'}
                                          </p>
                                          <p className="prod-item-title">
                                             {apiType[pageType].dataUrl.order_number}
                                          </p>
                                          <p className="prod-table-item-option">
                                             옵션 : {product.option_name} &#40;+ {product.option_price}원&#41;
                                             <span className="quantity only-mo">&nbsp;*수량&nbsp;:&nbsp;{product.real_buy_quantity}</span>
                                          </p>
                                          <div className='options-wrap'>
                                             <p className="prod-table-item-option cost mb">
                                                <strong>{(product.final_option_price).toLocaleString()}</strong>원
                                             </p>
                                             <p className="prod-table-item-option state mb">
                                                <strong className={`${product.state !== 1 ? 'red' : ''}`}>
                                                   {stateLabels[product.state]}
                                                   {product.state !== 1 &&
                                                      <span className='red'>&#40;수량&nbsp;:&nbsp;{product.refund_quantity}&#41;</span>
                                                   }
                                                </strong>
                                             </p>
                                          </div>
                                       </div>
                                    </div>
                                 </td>
                                 <td className='hide'>
                                    <div className="mb-prod-item-title">
                                       수량
                                    </div>
                                    <p className="prod-item-txt">
                                       <strong>
                                          {product.real_buy_quantity}
                                       </strong>
                                    </p>
                                 </td>
                                 <td className='hide'>
                                    <div className="mb-prod-item-title">
                                       결제금액
                                    </div>
                                    <p className="prod-item-txt">
                                       <strong>{(product.final_option_price).toLocaleString()}</strong>원
                                    </p>
                                 </td>
                                 <td className='hide'>
                                    <div className="mb-prod-item-title">
                                       상태
                                    </div>
                                    <p className="prod-item-txt">
                                       <strong className={`${product.state !== 1 ? 'red' : ''}`}>
                                          {stateLabels[product.state]}
                                          {product.state !== 1 &&
                                             <span className='red'>&#40;수량&nbsp;:&nbsp;{product.refund_quantity}&#41;</span>
                                          }
                                       </strong>
                                    </p>
                                 </td>
                              </tr>
                           )
                           : <tr>
                              <td>
                                 <div className="prod-table-item col-group">
                                    <div className="img-box">
                                       <img src={detailPageData.info.img.file_addr} alt="상품 썸네일" />
                                    </div>
                                    <div className="txt-wrap row-group">
                                       <p className="prod-item-label">
                                          {detailPageData.info.type === 0 ? 'Mind Insight' : 'Mind Therapy'}
                                       </p>
                                       <p className="prod-item-title">
                                          {apiType[pageType].dataUrl.order_number}
                                       </p>
                                       <p className="prod-table-item-option">
                                          옵션 : {detailPageData.option_name} &#40;+ {detailPageData.option_price}원&#41;
                                          <span className="quantity only-mo">&nbsp;*수량&nbsp;:&nbsp;{detailPageData.refund_quantity}</span>
                                       </p>
                                       <div className='options-wrap'>
                                          <p className="prod-table-item-option cost mb">
                                             <strong>{(detailPageData.final_option_price).toLocaleString()}</strong>원
                                          </p>
                                          <p className="prod-table-item-option state mb">
                                             <strong className={`${detailPageData.state && detailPageData.state !== 1 ? 'red' : ''}`}>
                                                {stateLabels[detailPageData.state && detailPageData.state]}
                                                {detailPageData.state && detailPageData.state !== 1 &&
                                                   <span className='red'>&#40;수량&nbsp;:&nbsp;{detailPageData.refund_quantity}&#41;</span>
                                                }
                                             </strong>
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </td>
                              <td className='hide'>
                                 <div className="mb-prod-item-title">
                                    수량
                                 </div>
                                 <p className="prod-item-txt">
                                    <strong>
                                       {detailPageData.refund_quantity}
                                    </strong>
                                 </p>
                              </td>
                              <td className='hide'>
                                 <div className="mb-prod-item-title">
                                    결제금액
                                 </div>
                                 <p className="prod-item-txt">
                                    <strong>{(detailPageData.final_option_price).toLocaleString()}</strong>원
                                 </p>
                              </td>
                              <td className='hide'>
                                 <div className="mb-prod-item-title">
                                    상태
                                 </div>
                                 <p className="prod-item-txt">
                                    <strong className={`${detailPageData.state && detailPageData.state !== 1 ? 'red' : ''}`}>
                                       {stateLabels[detailPageData.state && detailPageData.state]}
                                    </strong>
                                 </p>
                              </td>
                           </tr>
                        }
                     </tbody>
                  </table>
               </div>
            </div>
            <div className="mp-section">
               <div className="mp-section-title">
                  {apiType[pageType].finalInfo.title}
               </div>
               <div className="order-detail-list row-group">
                  {apiType[pageType].finalInfo.items.map((infoItem, index) =>
                     <div className="order-detail-item col-group" key={index}>
                        <div className="item-default">
                           {infoItem.name}
                        </div>
                        <div className="item-user">
                           {isNaN(infoItem.dataUrl) ?
                              <span
                                 className={`${infoItem.dataUrl === '환불반려' ? 'return-refund' : ''}`}
                                 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(infoItem.dataUrl) }}
                              />
                              : <>
                                 <strong className={`${infoItem.color} ${infoItem.isTotalCost ? 'total-price' : ''}`}>
                                    {infoItem.dataUrl.toLocaleString()}
                                 </strong>
                                 {pageType === 0 ?
                                    <span>원</span>
                                    : detailPageData.state && detailPageData.state !== 2 && <span>원</span>
                                 }
                              </>
                           }
                        </div>
                     </div>
                  )}
                  {/* {pageType === 0 &&
                     <>
                        <div className="order-detail-item col-group">
                           <div className="item-default red">
                              상품 할인
                           </div>
                           <div className="item-user red">
                              <strong className="red">- {(detailPageData.total_product_discount).toLocaleString()}</strong>원
                           </div>
                        </div>
                        <div className="order-detail-item col-group">
                           <div className="item-default red">
                              환불 금액
                           </div>
                           <div className="item-user red">
                              <strong className="red">- {(detailPageData.total_refund_price).toLocaleString()}</strong>원
                           </div>
                        </div>
                        <div className="order-detail-item col-group">
                           <div className="item-default">
                              <strong>최종 결제 금액</strong>
                           </div>
                           <div className="item-user">
                              <div className="total-price blue">
                                 <strong className="blue">{(detailPageData.final_total_price).toLocaleString()}</strong>원
                              </div>
                           </div>
                        </div>
                     </>
                  } */}
               </div>
            </div>
         </div>
      </div>
   )
}

export default PurchaseDetailPage
import React, { useEffect, useState } from 'react'
import AccessToken from '../../../hooks/token/AccessToken';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loading from '../../common/Loading';
import { useRecoilValue } from 'recoil';
import { LoginInfoAtom } from '../../../recoil/LoginInfoAtom';
import Pagination from '../../common/Pagination';

// Images
import { images } from '../../../assets/testPageImages';

const TestProgressInfo = ({ searchInfo }) => {

   const accessToken = AccessToken();
   const userInfo = useRecoilValue(LoginInfoAtom);
   const [progressData, setProgressData] = useState();

   // 검색, 페이지
   const [pageIndex, setPageIndex] = useState(1);

   const fetchData = async () => {
      try {
         const response = await axios.get(`${process.env.REACT_APP_API_URL}/mypage/progress/inspect/list?search=${searchInfo ? searchInfo : ''}&page=${pageIndex}`, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         setProgressData(response.data.data.list);
      } catch (error) {
         console.error(error)
      }
   }

   useEffect(() => {
      if (!accessToken) return;

      window.scrollTo(0, 0);
      fetchData();
   }, [accessToken, pageIndex, searchInfo])

   // 검색하면 1페이지로
   useEffect(() => {
      setPageIndex(1);
   }, [searchInfo])


   if (!progressData || userInfo === null) return <Loading />

   const isCounselor = userInfo.member_type === 1;

   return (
      <>
         {progressData.data.length > 0 ?
            progressData.data.map((listItem, index) => (
               <div className="test-result-item col-group" key={index}>
                  {/* 일반 회원일 때만 이미지 노출 */}
                  {!isCounselor &&
                     <div className="img">
                        <img src={listItem.img ? listItem.img.file_addr : images.life_score_sub_banner} alt="" />
                     </div>
                  }
                  <div className="txt-wrap row-group">
                     <div className="title-group row-group">
                        {!isCounselor ?
                           <>
                              <p className="eng-title">
                                 {listItem.type === 0 ? 'Mind Insight' : 'Life Cycle Score'}
                              </p>
                              <p className="title">
                                 {listItem.product_name}
                              </p>

                              <p className="txt">
                                 <strong>옵션명 : </strong>
                                 {listItem.inspect_name}
                              </p>
                           </>
                           : <div class="name-group col-group">
                              <p class="name">
                                 {listItem.name}
                              </p>
                              <p class="sub-title">
                                 {listItem.product_name}
                              </p>
                           </div>
                        }
                        <div className="detail-list col-group">
                           {isCounselor && listItem.birth &&
                              <div className="detail-item col-group">
                                 <i className="icon"></i>
                                 <p className="txt">
                                    {listItem.birth}
                                 </p>
                              </div>
                           }
                           {isCounselor && listItem.phone &&
                              <div className="detail-item col-group">
                                 <i className="icon"></i>
                                 <p className="txt">
                                    {listItem.phone}
                                 </p>
                              </div>
                           }
                           {isCounselor && listItem.email &&
                              <div className="detail-item col-group">
                                 <i className="icon"></i>
                                 <p className="txt">
                                    {listItem.email}
                                 </p>
                              </div>
                           }
                           {listItem.percent >= 100 && listItem.result_pwd &&
                              <div className="detail-item col-group">
                                 <i className="icon"></i>
                                 <p className="txt">
                                    {listItem.result_pwd}
                                 </p>
                              </div>
                           }
                        </div>
                     </div>
                     {/* 인생 점수 일 때는 step 노출 X */}
                     {listItem.type !== 1 &&
                        <div className="test-result-step">
                           <div className="test-title-group col-group">
                              <p className="test-title">검사 진행률</p>
                              <p className="test-title green">{listItem.percent}%</p>
                           </div>
                           <div className="test-step-group col-group">
                              <div className={`test-step-item row-group ${listItem.percent >= 25 ? 'active' : ''}`}>
                                 <div className="item-user"></div>
                                 <p className="item-default">STEP 01</p>
                              </div>
                              <div className={`test-step-item row-group ${listItem.percent >= 50 ? 'active' : ''}`}>
                                 <div className="item-user"></div>
                                 <p className="item-default">STEP 02</p>
                              </div>
                              <div className={`test-step-item row-group ${listItem.percent >= 75 ? 'active' : ''}`}>
                                 <div className="item-user"></div>
                                 <p className="item-default">STEP 03</p>
                              </div>
                              <div className={`test-step-item row-group ${listItem.percent >= 100 ? 'active' : ''}`}>
                                 <div className="item-user"></div>
                                 <p className="item-default">STEP 04</p>
                              </div>
                           </div>
                        </div>
                     }
                  </div>
                  {
                     isCounselor || listItem.type === 1 ? (
                        listItem.percent >= 100 ?
                           <Link
                              to={listItem.file_url}
                              className='btn active'
                              target='_blank'
                              rel='noreferrer noopener'
                           >
                              결과보기
                           </Link>
                           : <div className='btn' style={{ cursor: 'not-allowed' }}>결과보기</div>
                     ) : (
                        listItem.percent >= 100 ?
                           <Link
                              to={`/mind-insight-test/check-results?inspect=${listItem.id}`}
                              className='btn active'
                              target='_blank'
                              rel='noreferrer noopener'
                           >
                              결과보기
                           </Link>
                           : <Link
                              to={`/mind-insight-test/index?inspect=${listItem.id}`}
                              className='btn active'
                           >
                              검사진행
                           </Link>
                     )
                  }
               </div>
            ))
            :
            <div className="test-result-item col-group">
               <i className="icon"></i>
               내용이 존재하지 않습니다.
            </div>
         }
         <Pagination
            data={progressData}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
         />
      </>
   )
}

export default TestProgressInfo
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AccessToken from '../../../../hooks/token/AccessToken'
import { useParams } from 'react-router-dom';
import Loading from '../../../../components/common/Loading';
import DOMPurify from 'dompurify';
import { ShowErrorMsg } from '../../../../utils/ShowErrorMsg';

const InquiryDetail = () => {

   const { id } = useParams();
   const accessToken = AccessToken();

   const [detailData, setDetailData] = useState();

   const fetchData = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/mypage/inquiry/detail`, {
            id: id
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         setDetailData(response.data.data)
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
         window.location.href = '/'
      }
   }

   useEffect(() => {
      if (!id) {
         alert('다시 접속해 주세요.')
         window.location.href = '/'
      }

      fetchData();
   }, [])

   if (!detailData) return <Loading />

   return (
      <div className="myPageContent">
         <div className="mp-main-title-wrap border">
            <h2 className="mp-main-title">
               1:1 문의 상세
            </h2>
         </div>
         <div className="inquiry-detail">
            <div className="inquiry-detail-que">
               <div className="inquiry-detail-title-wrap row-group">
                  <div className="title-group col-group">
                     <p className={`${detailData.answer ? 'blue' : 'red'} state`}> {/* 답변 완료 시 complete 클래스 추가 */}
                        {detailData.answer ? '답변완료' : '답변대기'}
                     </p>
                     <p className="date">
                        {detailData.format_created_at}
                     </p>
                  </div>
                  <p className="inquiry-detail-title">
                     {detailData.title}
                  </p>
               </div>
               <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detailData.question) }}
                  className="inquiry-detail-txt"
               />
               <div className="file-download-group row-group">
                  {detailData.file &&
                     <a
                        href={`${process.env.REACT_APP_API_URL}/download?dir_type=inquiry&time_file_name=${detailData.file.time_file_name}`}
                        className="file-download-wrap col-group"
                     >
                        <i className="xi-folder-open icon"></i>
                        <p className="file-title">
                           {detailData.file.time_file_name}
                        </p>
                        <i style={{ marginLeft: 'auto' }} className="xi-download icon"></i>
                     </a>
                  }
               </div>
            </div>
            {detailData.answer &&
               <div className="inquiry-detail-ans">
                  <i className="icon xi-subdirectory-arrow"></i>
                  <p className="inquiry-detail-title">
                     마인드 인사이트 고객센터
                  </p>
                  <div className="inquiry-detail-txt">
                     <p
                        style={{ lineHeight: '1.35em' }}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detailData.answer) }}
                     />
                     <p className="inquiry-detail-date">
                        {detailData.format_answer_date}
                     </p>
                  </div>
               </div>
            }
         </div>
      </div>
   )
}

export default InquiryDetail
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { LoginInfoAtom } from '../../../recoil/LoginInfoAtom'
import AccessToken from '../../../hooks/token/AccessToken'
import ProductUseList from '../../../components/user/my-page/ProductUseList'
import Pagination from '../../../components/common/Pagination'

const UseOrGiftHistory = () => {
   // 개인 회원 사용, 선물내역 / 상담사회원 발송내역 공통으로 사용

   const loginInfo = useRecoilValue(LoginInfoAtom);
   const accessToken = AccessToken();

   const [listData, setListData] = useState();
   const [searchInfo, setSearchInfo] = useState('')
   const [pageIndex, setPageIndex] = useState(1);

   const fetchData = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/mypage/use-gift-send/insight/list?search=${searchInfo}&page=${pageIndex}`,
            {
               headers: {
                  Authorization: `Bearer ${accessToken}`
               }
            }
         );
         setListData(response.data.data);
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      if (!accessToken) return;

      window.scrollTo(0, 0);
      fetchData();
   }, [accessToken, pageIndex, searchInfo])


   const [searchValue, setSearchValue] = useState('');
   const searchOnChange = (e) => {
      setSearchValue(e.target.value)
   }
   const searchSubmit = (e) => {
      e.preventDefault();
      setPageIndex(1);
      setSearchInfo(searchValue)
   }

   return (
      <div className="myPageContent">
         <div className="mp-main-title-wrap col-group">
            <h2 className="mp-main-title">
               <span className="mb">My 정보 <i></i></span>
               {loginInfo.member_type === 0 ? '사용/선물 내역' : '발송 내역'}
            </h2>
            <div className="search-wrap">
               <form onSubmit={searchSubmit}>
                  <input
                     value={searchValue}
                     onChange={searchOnChange}
                     type="text"
                     className="search-input"
                     placeholder="이름을 입력하세요"
                  />
                  <button type='submit' className="search-btn">
                     <i></i>
                  </button>
               </form>
            </div>
         </div>

         <div className="table-wrap list-table-wrap">
            <table>
               <colgroup>
                  {loginInfo.member_type === 0 && <col width="6%" />}
                  <col width="8%" />
                  <col width="12%" />
                  <col width="20%" />
                  <col width="40%" />
                  <col width="20%" />
               </colgroup>
               <thead>
                  <tr>
                     {loginInfo.member_type === 0 && <th className="left">구분</th>}
                     <th className="left">이름</th>
                     <th className="left">연락처</th>
                     <th className="left">이메일</th>
                     <th className="left">상품명</th>
                     <th className="left">발송일자</th>
                  </tr>
               </thead>
               <ProductUseList listData={listData} />
            </table>
         </div>

         {listData &&
            <Pagination
               data={listData.list}
               pageIndex={pageIndex}
               setPageIndex={setPageIndex}
            />
         }
      </div>
   )
}

export default UseOrGiftHistory
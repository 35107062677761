import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil';

// Hooks
import { LoginInfoAtom } from './recoil/LoginInfoAtom';
import useConfirmToken from './hooks/token/useConfirmToken';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import TopMenu from './components/TopMenu';

// Style
import 'swiper/css';
import 'swiper/css/pagination';
import './css/common.css'
import './css/style.css'

const Root = () => {

  // 토큰 확인 API
  useConfirmToken();

  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
      <TopMenu />
    </div>
  )
}

export default Root
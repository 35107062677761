import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

// Images
import { images } from '../assets/images';
import styled from 'styled-components';

// styled
const ToTopWrap = styled.div`
   .Top_Scroll_btn {
      position: relative;
      width: 64px;
      height: 64px;
      border-radius: 100%;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: .3s;
   }
   .Top_Scroll_btn.active {
      opacity: 1;
   }
   .Top_Scroll_btn i {
      z-index: 2;
      font-size: 24px;
      color: #202020;
   }
   .Top_Scroll_btn .progress_bar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
   }
   .Top_Scroll_btn .progress_bar::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      background-color: #fff;
      border-radius: 100%;
   }
   @media screen and (max-width:1024px){
      .Top_Scroll_btn{
         width: 48px;
         height: 48px;
      }
   }
`

const TopMenu = () => {

   useEffect(() => {
      const topScrollBtn = document.querySelector('.Top_Scroll_btn');

      if (topScrollBtn) {
         topScrollBtn.addEventListener('click', function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
         });

         document.addEventListener('scroll', function () {
            if (document.documentElement.scrollTop > 88) {
               topScrollBtn.classList.add('active');
            } else {
               topScrollBtn.classList.remove('active');
            }

            const scrollPercent = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const color = getColorFromPercentage(scrollPercent);

            const progressBar = topScrollBtn.querySelector('.progress_bar');;
            if (progressBar) {
               progressBar.style.background = `conic-gradient(${color} 0% ${scrollPercent}%, #e4e4e4 ${scrollPercent}% 100%)`;
            }
         });
      }

      function getColorFromPercentage(percentage) {
         const startColor = [128, 128, 128]; // RGB 값으로 작성해야함!
         const endColor = [128, 128, 128];

         const color = startColor.map((start, i) => {
            return Math.round(start + (endColor[i] - start) * (percentage / 100));
         });

         return `rgb(${color.join(',')})`;
      }
   }, [])

   return (
      <div className="top-btn-wrap">
         <Link
            to='/mind-insight/mind-insight'
            className="btn test-btn"
         >
            <div className="hover-btn">
               <p>검사하기</p>
            </div>
            <div className="basic-btn">
               <img src={images.icon_pen} alt="" />
            </div>
         </Link>
         <a
            href="http://pf.kakao.com/_wZlxkG"
            target="_blank"
            className="btn inquiry-btn"
            rel='noreferrer noopener'
         >
            <div className="hover-btn">
               <p>문의하기</p>
            </div>
            <div className="basic-btn">
               <img src={images.icon_message} alt="" />
            </div>
         </a>
         <ToTopWrap className="top_btn_wrap">
            <button className="Top_Scroll_btn">
               <i className="xeicon xi-arrow-up"></i>
               <div className="progress_bar"></div>
            </button>
         </ToTopWrap>
      </div>

   )
}

export default TopMenu